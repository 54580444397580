import { createContext, useReducer, useEffect } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import SnackBar from "app/components/SnackBar";
import { useState } from "react";

export const UserBaseURL = process.env.REACT_APP_USER_URL;

const initialState = {
  user: null,
  userId: null,
  isInitialized: false,
  isAuthenticated: false,
  roles: ['user']
};

const reducer = (state, action) => {
  switch (action.type) {
    case "INIT":
      return { ...state, isInitialized: true, isAuthenticated: action.payload.isAuthenticated, user: action.payload.user, userId: action.payload.user?.userId || null, role: action.payload.role };
    case "LOGIN":
      return { ...state, isAuthenticated: true, user: action.payload.user, userId: action.payload.user?.userId };
    case "LOGOUT":
      return { ...state, isAuthenticated: false, user: null, userId: null };
    case "REGISTER":
      return { ...state, isAuthenticated: false, userId: action.payload?.userId };
    case "VERIFY":
      return { ...state, isAuthenticated: true, user: action.payload.user, userId: action.payload?.userId };
    case "INIT_INSTITUTES":
      return { ...state, isInitialized: true, isAuthenticated: action.payload.isAuthenticated, user: action.payload.user, userId: action.payload.user?.userId || null, roles: action.payload.roles };
    default:
      return state;
  }
};

const AuthContext = createContext({
  ...initialState,
  method: "JWT",
  login: () => { },
  logout: () => { },
  register: () => { },
  verifyEmail: () => { },
  resendOTP: () => { },
  instituteLogin: () => { }
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [response, setResponse] = useState({
    status: "error",
    message: "",
    open: false,
  })
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const userId = sessionStorage.getItem("userId");
  console.log("path:", pathname);
  const isInstitute = pathname === "/institute/login";
  useEffect(() => {
    // (async () => {
    //   try {
    //     const { data } = await axios.get("/api/auth/profile");
    //     // const user = { ...data.user, roles: data.user?.roles || [] };
    //     // console.log("Fetched user:", data.user);
    //     // dispatch({ type: "INIT", payload: { ...state, isAuthenticated: true, role: process.env.REACT_APP_ROLE } });
    //     // const { data } = await axios.get("/profile");
    //     dispatch({ type: "INIT", payload: { isAuthenticated: true, user: data.user } })
    //   } catch (err) {
    //     console.error(err);
    //     dispatch({ type: "INIT", payload: { isAuthenticated: false, user: null } });
    //   }
    // })();
    if (isInstitute) {
      console.log("institute auth");
      dispatch({ type: "INIT_INSTITUTES", payload: { isAuthenticated: true, user: null, roles: ['institutes'] } })
    } else if (userId) {
      dispatch({ type: "INIT", payload: { isAuthenticated: true, userId: userId } })
    } else {
      dispatch({ type: "INIT", payload: { isAuthenticated: false, user: null } })
    }
    if (userId && !isInstitute) {
      navigate("/user/dashboard")
    }

  }, []);

  if (!state.isInitialized) return null;

  /* User APIS */

  const login = async (email, password) => {
    try {
      const response = await axios.post(`${UserBaseURL}/user/login`, { email, password });
      const { user, token } = response.data
      console.log("login::", response)

      dispatch({ type: "LOGIN", payload: { user } });
      sessionStorage.setItem('token', token);
      sessionStorage.setItem("userId", user.id);
      sessionStorage.setItem("userRole", "USER");
      setTimeout(() => {
        navigate("/user/dashboard");
      }, 0);
    } catch (error) {
      console.error("Login failed:", error);
      // Swal.fire({ icon: "error", title: error.response.data.message, timer: 3000 })
      setResponse({
        status: "error",
        message: error.response.data.message || "Invalid Password or Email!",
        open: true
      });
    }
  };

  const register = async (email, country, username, password) => {
    try {
      // const response = await axios.post("/api/auth/register", { email, username, password });
      const response = await axios.post(`${UserBaseURL}/user/initiate`, { email, country });
      const { userId } = response.data;
      console.log("user::", response)
      dispatch({ type: "REGISTER", payload: { userId } });
      sessionStorage.setItem("email", email);
      return true
    } catch (error) {
      console.error("Registration failed:", error);
      // Swal.fire({ icon: "error", title: "Failed to register account. Please try again.", timer: 3000 });
      setResponse({
        status: "error",
        message: error.response.data.message || "Failed to register account. Please try again.",
        open: true
      });
      return false;
    }
  };

  const verifyEmail = async (otp) => {
    try {
      // const response = await axios.post("/api/auth/register", { email, username, password });
      console.log("OTP being sent:", otp);
      const response = await axios.post(`${UserBaseURL}/auth/verify-otp`, { userId: state.userId, otp });
      const { user } = response.data;
      console.log("verify::", response.data)
      dispatch({ type: "VERIFY", payload: { user: user, userId: state.userId } });
      Swal.fire({ icon: "success", title: "Your email has been successfully verified.", timer: 3000 }).then((res) => {
        sessionStorage.setItem("userId", state.userId);
        navigate("/user/create-account");
      })
    } catch (error) {
      console.error("Email verify failed:", error);
      // Swal.fire({ icon: "error", title: "Failed to create account. Please try again.", timer: 3000 })
      setResponse({
        status: "error",
        message: error.response.data.message || "Failed to create account. Please try again.",
        open: true
      });
    }
  };

  const resendOTP = async () => {
    try {
      // const response = await axios.post("/api/auth/register", { email, username, password });
      const response = await axios.post(`${UserBaseURL}/auth/resend-otp`, { userId: state.userId });
      const { user } = response.data;
      // dispatch({ type: "VERIFY", payload: { user } });
      // Swal.fire({ icon: "success", title: "Your email has been successfully verified.", timer: 3000 }).then((res) => {
      //   navigate("/")
      // })
      console.log("resend::", user)
    } catch (error) {
      console.error("Email verify failed:", error);
      setResponse({
        status: "error",
        message: error.response.data.message || "Something went wrong!",
        open: true
      });
    }
  };

  const logout = async () => {
    try {
      // await axios.post("/api/auth/logout");
      sessionStorage.clear();
      dispatch({ type: "LOGOUT" });
      navigate(pathname.startsWith("/institute") ? "/institute/login" : "/session/signin");
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  /* Institute APIS */
  const instituteLogin = async (email, password) => {
    try {
      const response = await axios.post(`${UserBaseURL}/institutes/login`, { email, password });
      const { user, token } = response.data
      console.log("login::", response)

      dispatch({ type: "LOGIN", payload: { user } });
      sessionStorage.setItem('token', token);
      sessionStorage.setItem("userId", user.id);
      sessionStorage.setItem("userRole", "INSTITUTE");
      setTimeout(() => {
        navigate("/institute/dashboard");
      }, 0);
    } catch (error) {
      console.error("Login failed:", error);
      // Swal.fire({ icon: "error", title: error.response.data.message, timer: 3000 })
      setResponse({
        status: "error",
        message: error.response.data.message || "Invalid Password or Email!",
        open: true
      });
    }
  };

  console.log("initial", state)

  return (
    <AuthContext.Provider value={{ ...state, method: "JWT", login, logout, register, verifyEmail, resendOTP, instituteLogin }}>
      {children}
      {(pathname === "/session/signin" || pathname === "/session/signup" || pathname === "/institute/login") && <SnackBar view={response.open} status={response.status} message={response.message} onClose={() => setResponse((prev) => ({ ...prev, open: false }))} />}
    </AuthContext.Provider>
  );
};

export default AuthContext;

