import { Navigate, useLocation } from "react-router-dom";
// HOOK
import useAuth from "app/hooks/useAuth";

export default function AuthGuard({ children }) {
  // const { isAuthenticated } = useAuth();
  // const { pathname } = useLocation();

  // if (isAuthenticated) return <>{children}</>;

  // return <Navigate replace to="/session/signin" state={{ from: pathname }} />;
  // const { user } = useAuth();
  // console.log("authGuard::", user);

  // ****************** ////

  const { user, roles, isAuthenticated } = useAuth();
  const { pathname } = useLocation();
  if (isAuthenticated) return <>{children}</>;
  if (!isAuthenticated) {
    return <Navigate replace to="/session/signin" state={{ from: pathname }} />;
  }

  if (!roles.includes("evaluator") && !roles.includes("admin")) {
    return <Navigate to="/session/404" />;
  }
}
