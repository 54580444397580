import React from 'react';
import Questions from './Question';
import DocumentViewer from './DocumentViewer';
import { Grid, Box, Container, Stack, Paper } from '@mui/material';
import { QCDocument } from 'dummy_data/data';
import ResponsiveAppBar from 'app/components/Appbar';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { fetchQCDocumentQuestions, submitQCDocumentReviews } from 'services/apiService';




const CombineScreen = () => {
    const { id } = useParams();
    // console.log("psr,s", id)
    // console.log("combineurl", QCDocument.application.documents[0].file_url)
    const [data, setData] = useState()
    const [error, setError] = useState(null);
    const [isLastQuestion, setIsLastQuestion] = useState(false);
    const [isNormalScreen, setIsNormalScreen] = useState(true); // Tracks whether we are in normal mode


    const handleResetLayout = () => {
        setIsNormalScreen(true);
        setIsLastQuestion(false); // Reset to ensure proper flex values
    };



    useEffect(() => {
        const fetchData = async () => {
            try {
                console.log("Fetching questions for ID:", id);
                const data = await fetchQCDocumentQuestions(id);
                setData(data);
            } catch (err) {
                setError(err.message || 'Failed to fetch questions');
            }
        };

        if (id) {
            fetchData();
        }
    }, [id]);

    console.log("dataog", data)

    // console.log("data", data?.evaluation?.document?.fileUrl)

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!data) {
        return <div>Loading...</div>;
    }
    const handleSubmit = async (submission) => {
        try {
            console.log('Submitting data:', submission);
            console.log('ID:', id);

            const response = await submitQCDocumentReviews(submission, id);
            console.log('Submission successful:', response);
        } catch (error) {
            console.error('Error submitting data:', error);
        }
    };

    return (



        <Stack
            direction="row"
            sx={{
                height: "100vh", // Full height of the screen
                overflow: "hidden", // Prevents unwanted scrolling at the container level
            }}
        >
            {/* Left: PDF Viewer */}
            <Box
                sx={{
                    // flex: 0.8, // Occupies 70% of the space
                    // flex: isLastQuestion ? 0.3 : 0.8,
                    flex: isLastQuestion && !isNormalScreen ? 0 : 0.8,
                    overflowY: "auto", // Enables scrolling for the PDF viewer
                    backgroundColor: "#f0f0f0", // Optional for visual distinction
                    transition: "flex 0.5s ease",
                }}
            >
                <DocumentViewer
                    // fileUrl={QCDocument.application.documents[0].file_url}
                    fileUrl={data?.evaluation?.document?.fileUrl}
                />
            </Box>

            {/* Right: Questions Component */}
            <Box
                sx={{
                    // flex: 0.2, // Occupies 30% of the space
                    overflowY: "auto", // Enables scrolling within the right section
                    // flex: isLastQuestion ? 0.8 : 0.2,
                    flex: isLastQuestion && !isNormalScreen ? 1 : 0.2,
                    transition: "flex 0.5s ease",
                }}
            >
                <Paper
                    elevation={2}
                    sx={{
                        height: "100%", // Full height of the parent container
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Questions
                        questions={data?.evaluation?.checklistQuestions}

                        onSubmit={handleSubmit}
                        setIsLastQuestion={setIsLastQuestion}
                        isLast={isLastQuestion}
                        isNormalScreen={isNormalScreen}
                        setIsNormalScreen={setIsNormalScreen}
                        handleResetLayout={handleResetLayout}

                    />
                </Paper>
            </Box>
        </Stack>



    );
};

export default CombineScreen;
