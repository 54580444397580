import React, { useState, useEffect } from "react";
import {
    Button,
    TextField,
    Box,
    Typography,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";

const Questions = ({
    questions,
    onSubmit,
    setIsLastQuestion,
    isLast,
    isNormalScreen,
    setIsNormalScreen,
    handleResetLayout
}) => {
    console.log("question", questions);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [answers, setAnswers] = useState({});
    // const [toogleRemark, setToggleRemark] = useState(false)
    const currentQuestion = questions[currentIndex];
    const isLastQuestion = currentIndex >= questions.length;

    const navigate = useNavigate();

    useEffect(() => {
        setIsLastQuestion(currentIndex >= questions.length);
    }, [currentIndex, questions.length, setIsLastQuestion, isLast]);

    const handleGoBack = () => {
        navigate("/qc/list"); // Replace "/" with the route for your home screen
    };

    // const handleAnswerChange = (answer) => {
    //     setToggleRemark(true)
    //     setAnswers((prev) => ({
    //         ...prev,
    //         [currentQuestion?.id]: {
    //             ...prev[currentQuestion?.id],
    //             question: currentQuestion?.text,
    //             answer
    //         }
    //     }));
    // };

    const handleAnswerChange = (answer) => {
        setAnswers((prev) => ({
            ...prev,
            [currentQuestion?.question_id]: {
                ...prev[currentQuestion?.question_id],
                question: currentQuestion?.question,
                answer,
                toggleRemark: true // Set toggleRemark to true when an answer is selected
            }
        }));
    };

    const handleRemarksChange = (remark) => {
        setAnswers((prev) => ({
            ...prev,
            [currentQuestion?.question_id]: {
                ...prev[currentQuestion?.question_id],
                question: currentQuestion?.question,
                remark
            }
        }));
    };

    const handleNext = () => {
        if (!isLastQuestion) {
            setCurrentIndex((prevIndex) => prevIndex + 1);
            setIsNormalScreen(false);
        }
    };

    const handlePrev = () => {
        if (currentIndex > 0) {
            setCurrentIndex((prevIndex) => prevIndex - 1);
            setIsNormalScreen(false);
        }
    };

    // const handleSubmit = () => {
    //     onSubmit(Object.values(answers));
    // };

    const handleSubmit = (status) => {
        const formattedData = {
            status: status,
            reviews: questions.map((question, index) => ({
                question_id: question.question_id, // assuming each question object has an `id`
                question: question.question, // assuming each question object has a `text` property
                answer: answers[question.question_id]?.answer || "", // default to empty if no answer
                order: index + 1 // order starts from 1
                // remark: answers[question.id]?.remark || "" // include remark if available
            }))
        };

        // console.log("forma", formattedData)
        onSubmit(formattedData);
    };

    const onReview = () => {
        handleResetLayout();
        if (currentIndex > 0) {
            setCurrentIndex((prevIndex) => prevIndex - 1);
            setIsNormalScreen(false);
        }
    };

    const selectedAnswer = answers[currentQuestion?.question_id]?.answer || "";
    const currentRemark = answers[currentQuestion?.question_id]?.remark || "";
    const currentAnswerState = answers[currentQuestion?.question_id] || {};
    const toggleRemark = currentAnswerState.toggleRemark || false;

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                height: "100vh" // Full height of the sticky container
            }}
        >
            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <Button
                    variant="text"
                    color="primary"
                    startIcon={<ArrowBack />}
                    // onClick={handleGoBack} // Function to navigate back
                    onClick={() => navigate(-1)}
                >
                    Back to Home
                </Button>
            </Box>

            {!isLastQuestion ? (
                <Paper
                    elevation={2}
                    sx={{
                        flex: 1, // Take up all available vertical space
                        display: "flex",
                        flexDirection: "column",
                        height: "100vh" // Full height
                    }}
                >
                    {/* Question Content */}
                    <Box sx={{ flexGrow: 1, overflowY: "auto", padding: 2 }}>
                        <Typography variant="h6" gutterBottom>
                            {`Question ${currentIndex + 1}`}
                        </Typography>
                        <Paper
                            elevation={1}
                            sx={{
                                border: "1px solid #ddd",
                                borderRadius: 1,
                                // mb: 2,
                                padding: 10, // Big padding for the box
                                position: "relative", // Position relative to align text properly
                                width: "100%", // Optional, for consistency
                                backgroundColor: "#75b5d9"
                            }}
                        >
                            <Typography
                                sx={{
                                    position: "absolute", // Align text to the top-left
                                    top: 16, // Adjust as needed
                                    left: 16, // Adjust as needed
                                    fontFamily: "sans-serif",
                                    fontSize: "18px",
                                    color: "#fff"
                                }}
                            >
                                {currentQuestion?.question}
                            </Typography>
                        </Paper>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center", // Align buttons to the center of the column
                                mt: 5 // Add margin from the question above
                            }}
                        >
                            <Button
                                variant={selectedAnswer === "Yes" ? "contained" : "outlined"}
                                color="primary"
                                fullWidth
                                sx={{ mb: 1, p: 2 }}
                                onClick={() => handleAnswerChange("Yes")}
                            >
                                <Typography sx={{ fontWeight: "500" }}>Yes</Typography>
                            </Button>
                            <Button
                                variant={selectedAnswer === "No" ? "contained" : "outlined"}
                                color="primary"
                                fullWidth
                                sx={{ mb: 2, p: 2 }}
                                onClick={() => handleAnswerChange("No")}
                            >
                                <Typography sx={{ fontWeight: "800" }}>No</Typography>
                            </Button>
                        </Box>
                        {toggleRemark && (
                            <TextField
                                label="Remarks"
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={3}
                                value={currentRemark}
                                onChange={(e) => handleRemarksChange(e.target.value)}
                                margin="normal"
                            />
                        )}
                    </Box>

                    {/* Fixed Buttons at the Bottom */}
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: 2,
                            backgroundColor: "white",
                            borderTop: "1px solid #ddd"
                        }}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={currentIndex === 0}
                            onClick={handlePrev}
                        >
                            Prev
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleNext}
                            disabled={!selectedAnswer}
                        >
                            Next
                        </Button>
                    </Box>
                </Paper>
            ) : (
                <Paper
                    elevation={2}
                    sx={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                        height: "100%"
                    }}
                >
                    {/* Summary Section */}
                    <Box sx={{ flexGrow: 1, overflowY: "auto", padding: 2 }}>
                        <Typography variant="h6" gutterBottom>
                            Summary
                        </Typography>

                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <strong>Question</strong>
                                        </TableCell>
                                        <TableCell>
                                            <strong>Answer</strong>
                                        </TableCell>
                                        <TableCell>
                                            <strong>Remarks</strong>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Object.values(answers).map((item, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{item.question}</TableCell>
                                            <TableCell>{item.answer}</TableCell>
                                            <TableCell>{item.remark || "N/A"}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>

                    {/* Submit Button */}
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: 2,
                            backgroundColor: "white",
                            borderTop: "1px solid #ddd"
                        }}
                    >
                        {/* <Button
                            variant="contained"
                            color="primary"
                            disabled={currentIndex === 0}
                            onClick={handlePrev}
                        >
                            Prev
                        </Button> */}
                        <Button variant="contained" color="primary" onClick={onReview}>
                            Edit
                        </Button>
                        <Box>
                            <Button variant="contained" color="primary" onClick={() => handleSubmit("APPROVE")}>
                                Approve
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleSubmit("REJECT")}
                                sx={{ marginLeft: "20px" }}
                            >
                                Reject
                            </Button>
                        </Box>
                    </Box>
                </Paper>
            )}
        </Box>
    );
};

export default Questions;
