import React from 'react'
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Slide from "@mui/material/Slide";

const SnackBar = ({ view, status, message, onClose }) => {
    const [open, setOpen] = React.useState(view || false);
    const TransitionUp = (props) => <Slide {...props} direction="left" timeout={800} />;

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };
    return (
        <div>
            <Snackbar
                open={view}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                autoHideDuration={3000}
                onClose={onClose}
                sx={{ top: "100px !important", }}
                TransitionComponent={TransitionUp}
            >
                <Alert
                    onClose={onClose}
                    severity={status === "success" ? "success" : "error"}
                    // variant={status === "success" ? "filled" : "standard"}
                    // severity='success'
                    variant='standard'
                    sx={{ width: '100%' }}
                >
                    {message || "This is a success Alert inside a Snackbar!"}
                </Alert>
            </Snackbar>
        </div>
    )
}

export default SnackBar