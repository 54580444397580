import React, { createContext, useReducer } from "react";
import { formReducer, initialState } from "./formReducer";

export const FormContext = createContext();

export const FormContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(formReducer, initialState);

    return (
        <FormContext.Provider value={{ state, dispatch }}>
            {children}
        </FormContext.Provider>
    );
};
