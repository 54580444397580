import React, { useState, Fragment, useEffect } from 'react'
import { Box, styled } from '@mui/material';
import HeaderUBV from 'app/components/HeaderUBV';
import Swal from "sweetalert2";
import axios from "axios";
import { UserBaseURL } from "app/contexts/JWTAuthContext";
import { useLocation } from 'react-router-dom';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';


const StyledRoot = styled("div")(() => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#76B3D8",
    minHeight: "100%",
    padding: "1rem",
    // marginTop: "-80px",
    // marginTop: "5rem",
    "& .card-container": {
        marginTop: "85px",
        width: "80%",
        height: "85vh",
        // maxWidth: "1200px",
        backgroundColor: "rgba(288, 288, 288, 0.9)",
        // backgroundColor: "#e9e9e9",
        padding: "1rem",
        boxSizing: "border-box",
        // overflowY: "scroll"
    },

    "@media (max-width: 768px)": {
        padding: "1rem",
        marginTop: "2rem",
        "& .card-container": {
            padding: "0.5rem",
        },
    },
    // "@media (max-width: 480px)": {
    //     "& .application-card": {
    //         padding: "0.5rem",
    //     },
    // },
}));

const ReportPage = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const toolbarPluginInstance = toolbarPlugin();
    const { Toolbar } = toolbarPluginInstance;
    console.log("link::", searchParams.get("link"));
    let pdfFile = "/Aquib__Shaikh.pdf";
    let link = "https://ubv-staging.s3.eu-north-1.amazonaws.com/documents/1733768084543-Test.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIA6JQ44ST7WM2ZGYGO%2F20241216%2Feu-north-1%2Fs3%2Faws4_request&X-Amz-Date=20241216T195910Z&X-Amz-Expires=300&X-Amz-Signature=9b8eeeac5a582915a7a72e530a6504b4cc17541dff37dbf13d82e0634295e36b&X-Amz-SignedHeaders=host&x-id=GetObject"
    return (
        <Fragment>
            <HeaderUBV />
            <StyledRoot>
                <Box className="card-container">
                    {/* Render the toolbar */}
                    {/* <Box sx={{ padding: '0.5rem', backgroundColor: '#ffffff' }}>
                        <Toolbar />
                    </Box> */}

                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                        <Viewer
                            fileUrl={pdfFile} // Replace this with `fileUrl` if passed dynamically
                            plugins={[toolbarPluginInstance]} // Pass the toolbar plugin
                        />
                    </Worker>

                    {/* <iframe src={link} width="100%" height="600px"></iframe> */}
                </Box>
            </StyledRoot>
        </Fragment>
    )
}

export default ReportPage