// import React from "react";
// import { Avatar, Box, Typography, Button, Paper } from "@mui/material";

// function StudentProfile({ data }) {
//     return (
//         <Paper

//             sx={{
//                 display: "flex",
//                 flexDirection: "column",
//                 alignItems: "center",
//                 justifyContent: "center",
//                 minHeight: "100vh",
//                 backgroundColor: "#f0f0f0", // Plain color background
//                 padding: 2,
//                 flex: "0.3"
//             }}
//         >
//             {/* Avatar */}
//             <Avatar
//                 sx={{
//                     width: 100,
//                     height: 100,
//                     backgroundColor: "#1976d2", // Blue color
//                     fontSize: 36,
//                 }}
//             >
//                 RF
//             </Avatar>

//             {/* Name */}
//             <Typography variant="h5" sx={{ marginTop: 2 }}>
//                 {/* Neil Sims */}
//                 {data?.authority_email}
//             </Typography>

//             {/* Job Title */}
//             <Typography variant="body1" color="text.secondary" sx={{ marginBottom: 1 }}>
//                 {/* Senior Software Engineer */}
//                 Status: {data?.status}
//             </Typography>

//             {/* Location */}
//             {/* <Typography variant="body2" color="text.secondary">
//                 New York, USA
//             </Typography> */}


//         </Paper>
//     );
// }

// export default StudentProfile;

import React from "react";
import { Paper, Avatar, Typography, Box, Divider } from "@mui/material";
import { useEffect } from "react";
import { fetchEvalutorDetailsdData } from "services/apiService";
import { useState } from "react";

function StudentProfile({ data }) {
    console.log("pro", data);
    const [details, setDetails] = useState(null);

    useEffect(() => {
        const getData = async () => {
            try {
                const details = await fetchEvalutorDetailsdData(data);
                console.log("dat", details);
                setDetails(details);
            } catch (error) {
                console.error("Error Evalutor Details:", error.message);
            }
        };
        getData();
    }, [data]);

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "100vh",
                backgroundColor: "#f0f0f0",
                padding: 2,
                flex: "0.4",
            }}
        >
            {/* Avatar */}
            <Avatar
                sx={{
                    width: 100,
                    height: 100,
                    backgroundColor: "#1976d2",
                    fontSize: 36,
                }}
            >
                RF
            </Avatar>

            {/* ID */}
            <Typography variant="h5" sx={{ marginBottom: 3 }}>
                {data?.id || "ABC123"}
            </Typography>

            {/* Bank Details Section */}
            <Paper
                elevation={3}
                sx={{
                    width: "90%",
                    padding: "10px",
                    marginBottom: 2,
                    borderRadius: "10px",
                    backgroundColor: "#fff",
                }}
            >
                <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                    Bank Details
                </Typography>
                <Divider sx={{ borderColor: "#fff", marginBottom: 1 }} />
                <Typography variant="body2" color="text.secondary">
                    Bank Name: {details?.evaluation?.bankName || "N/A"}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    Document Type: {details?.evaluation?.documentType || "N/A"}
                </Typography>
            </Paper>

            {/* Evaluation Section */}
            <Paper
                elevation={3}
                sx={{
                    width: "90%",
                    padding: "10px",
                    borderRadius: "10px",
                    backgroundColor: "#fff",
                }}
            >
                <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                    Evaluation
                </Typography>
                <Divider sx={{ borderColor: "#fff", marginBottom: 1 }} />
                <Typography variant="body2" color="text.secondary">
                    Email: {details?.evaluation?.authorityEmail || "N/A"}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    Action: {details?.evaluation?.action || "N/A"}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    Contact: {details?.evaluation?.authorityContact || "N/A"}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    Status: {details?.evaluation?.status || "N/A"}
                </Typography>
            </Paper>
        </Box>
    );
}


export default StudentProfile;

