import React, { useState, useRef, useEffect } from "react";
import {
    Box,
    Button,
    Checkbox,
    Stack,
    Typography,
    IconButton,
    Paper,
    TextField,
    Avatar as MuiAvatar,
    Grow,
    Slide
} from "@mui/material";
import { MessageList, Navbar, Avatar, ChatList } from "react-chat-elements";
import ReactQuill from "react-quill";
import "react-chat-elements/dist/main.css";
import "react-quill/dist/quill.snow.css";
import "./customQuillStyles.css";
import Modal from "../../components/Modal";
import { useParams, useNavigate } from "react-router-dom";
import SendIcon from "@mui/icons-material/Send";
// import RemoveDoneIcon from '@mui/icons-material/RemoveDone';
// import CheckBoxIcon from '@mui/icons-material/CheckBox';
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import UnpublishedOutlinedIcon from "@mui/icons-material/UnpublishedOutlined";
import StudentProfile from "../profile/StudentProfile";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { EvaluatorChat, EvaluatorFetch } from "dummy_data/data";
import { styled } from "@mui/material/styles";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import ResponsiveAppBar from "app/components/Appbar";
import { fetchChat, fetchEvalutorDashboardData, sendFollowUpChat, submitEvalutorApproveReject } from "services/apiService";
import { formatDocumentType } from "app/utils/FormatText";

function ChatWindow() {

    const [chatData, setChatData] = useState(EvaluatorChat[0]?.application);
    // const [selectedChat, setSelectedChat] = useState(EvaluatorChat[0]?.application);
    const [selectChat, setSelectChat] = useState(null)
    const { id } = useParams();
    const navigate = useNavigate();
    const [remarks, setRemarks] = useState("");
    const [messageContent, setMessageContent] = useState("");
    const [selectedMessages, setSelectedMessages] = useState({});
    const quillRef = useRef(null);
    const [isModalOpen, setModalOpen] = useState(false);
    const [showCheckboxes, setShowCheckboxes] = useState(false);
    const [modalAction, setModalAction] = useState(""); // Tracks the action: "approve" or "reject"
    const [actionMessages, setActionMessages] = useState([]); // Stores the messages selected for action
    const [isTextAreaEnabled, setIsTextAreaEnabled] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [profileVisible, setProfileVisible] = useState(false);
    const [evalutorData, setEvalutorData] = useState(null);
    const [error, setError] = useState(null);
    const [chatHistory, setChatHistory] = useState(null)



    useEffect(() => {
        const getData = async () => {
            try {
                const dashboardData = await fetchEvalutorDashboardData();
                setEvalutorData(dashboardData);
                const firstdata = dashboardData?.evaluations[0];
                const applicationId = dashboardData?.evaluations[0]?.evaluationId;
                if (firstdata?.action) {
                    setSelectChat(firstdata)
                }
                else {
                    try {
                        const response = await fetchChat(applicationId)
                        console.log("res", response)
                        setChatHistory(response)

                    } catch (err) {
                        console.error("Error initiating chat:", err.message);
                    }
                }
            } catch (err) {
                setError(err.message || 'Failed to fetch data');
            }
        };

        getData();
    }, []);

    // console.log("ev", evalutorData)

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!evalutorData) {
        return <div>Loading...</div>;
    }


    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleProfileToggle = () => {
        setProfileVisible(!profileVisible);
    };

    const MessageBox = styled(Box)(({ theme, position }) => ({
        position: "relative",
        backgroundColor: position === "right" ? "#e0f7fa" : "#f1f1f1",
        borderRadius: "8px",
        boxShadow: theme.shadows[1],
        padding: theme.spacing(1.5),
        maxWidth: "100%",
        marginBottom: theme.spacing(1),
        ...(position === "right"
            ? {



                borderBottomLeftRadius: "0px",
                "&::after": {
                    content: '""',
                    position: "absolute",
                    top: "10px",
                    left: "-10px",
                    borderWidth: "10px",
                    borderStyle: "solid",
                    borderColor: "transparent #f1f1f1 transparent transparent"
                }
            }
            : {
                borderBottomRightRadius: "0px",
                "&::after": {
                    content: '""',
                    position: "absolute",
                    top: "10px",
                    right: "-10px",
                    borderWidth: "10px",
                    borderStyle: "solid",
                    borderColor: "#e0f7fa transparent transparent transparent"
                }
            })
    }));

    // Handle selecting a chat item
    const handleChatClick = async (application_id) => {

        const selectedChat = evalutorData?.evaluations?.find(
            (app) => app.evaluationId === application_id
        );
        if (!selectedChat?.action) {
            try {
                const response = await fetchChat(application_id)
                console.log("res", response)
                setChatHistory(response)
            } catch (err) {
                console.error("Error initiating chat:", err.message);
            }
        }
        // console.log("appli", application_id);
        // // console.log("se;e", selectedChat);
        // console.log("chat", chatHistory)
        // console.log("lenght", chatHistory?.chat_history?.length)
        setSelectChat(selectedChat);
    };

    // const chatItems = EvaluatorFetch.evaluation_requests.map((request) => ({
    //     avatar: "https://avatars.githubusercontent.com/u/80540635?v=4",
    //     alt: "Application",
    //     title: request.application_id,
    //     // subtitle: request.document_type,
    //     subtitle: (
    //         <>
    //             {" "}
    //             {request.document_type}
    //             <span
    //                 className="right-aligned-status"
    //                 style={{
    //                     marginLeft: "10px",
    //                     // display: "inline-block",

    //                     width: "100px",
    //                     border: "1px solid #75b5d9", // Uniform border color
    //                     // padding: "4px 8px",
    //                     borderRadius: "4px",
    //                     color: "#75b5d9", // Text color matching the border
    //                     textAlign: "center"
    //                 }}
    //             >
    //                 {request.status}{" "}
    //             </span>{" "}
    //         </>
    //     ),
    //     date: new Date(request.submitted_at),
    //     status: request.status,
    //     unread: request.priority === "High" ? 3 : 0,
    //     application_id: request.application_id
    //     // onClick: () => { console.log("click me") }
    //     // handleChatClick(request.application_id)
    // }));

    const chatItems = evalutorData?.evaluations?.map((request) => ({
        avatar: "https://avatars.githubusercontent.com/u/80540635?v=4",
        alt: "Application",
        title: request?.evaluationId,
        // subtitle: request.document_type,
        subtitle: (
            <>
                {formatDocumentType(request?.documentType)}
                <span
                    className="right-aligned-status"
                    style={{
                        marginLeft: "10px",
                        // display: "inline-block",

                        width: "100px",
                        border: "1px solid #75b5d9", // Uniform border color
                        // padding: "4px 8px",
                        borderRadius: "4px",
                        color: "#75b5d9", // Text color matching the border
                        textAlign: "center"
                    }}
                >
                    {request?.status === "VALIDATION_PENDING"
                        ? "Pending"
                        : request?.status === "VALIDATION_IN_REVIEW"
                            ? "InReview"
                            : request?.status}
                </span>{" "}
            </>
        ),
        date: new Date(request?.submittedAt),
        // status: request.status,
        // unread: request.priority === "High" ? 3 : 0,
        application_id: request?.evaluationId
        // onClick: () => { console.log("click me") }
        // handleChatClick(request.application_id)
    }));

    const filteredChatItems = chatItems.filter((item) => {
        return (
            item.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.subtitle.toLowerCase().includes(searchQuery.toLowerCase())
        );
    });

    // Handle sending a message
    // const handleSendMessage = () => {
    //     const htmlContent = quillRef.current.getEditor().root.innerHTML;

    //     if (htmlContent.trim()) {
    //         const newMessage = {
    //             text: htmlContent,
    //             dateTime: new Date().toLocaleString(),
    //             sender: "me",
    //         };

    //         setChatData((prevData) => ({
    //             ...prevData,
    //             messages: [...prevData.messages, newMessage],
    //         }));

    //         setMessageContent("");
    //     }
    // };

    // const handleSendMessage = () => {
    //     const quillEditor = quillRef.current.getEditor();
    //     const htmlContent = quillEditor.root.innerHTML; // Get raw HTML
    //     const plainText = quillEditor.getText(); // Get plain text

    //     if (plainText.trim()) {
    //         const newMessage = {
    //             text: htmlContent, // Save HTML content for processing styles
    //             dateTime: new Date().toLocaleString(),
    //             sender: "me"
    //         };

    //         setChatData((prevData) => ({
    //             ...prevData,
    //             messages: [...prevData.messages, newMessage]
    //         }));

    //         setMessageContent(""); // Clear the editor
    //     }
    // };

    const handleSendMessage = async (id) => {
        const quillEditor = quillRef.current.getEditor();
        // const plainText = quillEditor.getText().trim();
        const htmlContent = quillEditor.root.innerHTML;
        const plainText = quillEditor.getText();

        if (plainText.trim()) {
            const newMessage = {
                // sender: "Evaluator",
                message: htmlContent,
                // message: plainText,
                // timestamp: new Date().toISOString()
            };
            console.log("new", newMessage);


            const response = await sendFollowUpChat(id, newMessage.message);
            console.log("Message sent successfully:", response);
            setChatHistory((prev) => ({
                ...prev,
                chat_history: [...prev.chat_history, newMessage]
            }))


            // Send the message to the API
            // const response = await fetch(`https://api.example.com/messages`, {
            //     method: "POST",
            //     headers: { "Content-Type": "application/json" },
            //     body: JSON.stringify(newMessage),
            // });
            // const result = await response.json();

            // if (result.status === "success") {
            // Update UI with new message
            // setChatData((prev) => ({
            //     ...prev,
            //     chat_history: [...prev.chat_history, newMessage]
            // }));
            setMessageContent("");
            // }
        }

    }


    // };

    // const handleSendMessage = async () => {
    //     const quillEditor = quillRef.current.getEditor();
    //     const plainText = quillEditor.getText();

    //     if (plainText.trim()) {
    //         const newMessage = {
    //             message: plainText.trim(),
    //         };

    //         console.log("New message body:", newMessage);

    //         // try {
    //         //     const response = await sendFollowUpChat(id, newMessage.message);
    //         //     console.log("Message sent successfully:", response);
    //         // } catch (error) {
    //         //     console.error("Error sending message:", error);
    //         // }
    //     } else {
    //         console.log("Message is empty, not sending.");
    //     }
    // };

    // Handle checkbox change
    const handleCheckboxChange = (index) => {
        setSelectedMessages((prevSelected) => ({
            ...prevSelected,
            [index]: !prevSelected[index]
        }));
    };

    const handleToggleCheckboxes = () => {
        setShowCheckboxes(!showCheckboxes);
        if (showCheckboxes) {
            setSelectedMessages({});
        }
    };

    // const handleAction = (action) => {
    //     const selectedMsgs = chatData.messages.filter((_, idx) => selectedMessages[idx]);

    //     setActionMessages(selectedMsgs); // Store selected messages
    //     setModalAction(action); // Store action
    //     setModalOpen(true); // Open the modal
    // };

    const handleAction = (action) => {
        // const selectedMsgs = Object.entries(selectedMessages)
        //     .filter(([_, isSelected]) => isSelected)
        //     .map(([index]) => chatData.chat_history[index].message_id);

        setModalAction(action);
        setModalOpen(true);

    };

    // const handleConfirmAction = () => {
    //     console.log(`Action: ${modalAction}`, actionMessages);

    //     // Process the messages as per the action
    //     if (modalAction === "approve") {
    //         console.log("Approving the selected messages...");
    //     } else if (modalAction === "reject") {
    //         console.log("Rejecting the selected messages...");
    //     }

    //     // Clear modal state
    //     setModalOpen(false);
    //     setActionMessages([]);
    //     setModalAction("");
    // };

    // Transform chatData.messages to fit the MessageList format

    // const handleConfirmAction = async () => {
    //     // const selectedMsgs = chatData.chat_history.filter((_, idx) => selectedMessages[idx]);
    //     const selectedMsgs = chatHistory?.chat_history.filter((_, idx) => selectedMessages[idx]);
    //     console.log("checkin", selectedMsgs)

    //     // Extract both the message IDs and their text
    //     const quotedMessageIds = selectedMsgs.map((msg) => msg.message_id);
    //     // const quotedMessagesText = selectedMsgs.map((msg) => msg.message);

    //     console.log("Selected Message IDs:", quotedMessageIds);
    //     // console.log("Selected Messages Text:", quotedMessagesText);

    //     const payload = {
    //         status: modalAction === "approve" ? "Approved" : "Rejected",
    //         quoted_message_ids: quotedMessageIds,
    //         // remarks: "" // You can allow the user to add optional remarks
    //     };

    //     console.log("payload", payload);

    //     // Clear modal and selection state
    //     setModalOpen(false);
    //     setSelectedMessages({});
    //     setActionMessages([]);
    //     setModalAction("");
    // };


    const handleConfirmAction = async () => {
        try {
            // Filter chat history based on selected messages
            const selectedMsgs = chatHistory?.chat_history.filter((_, idx) => selectedMessages[idx]);
            console.log("Checking selected messages:", selectedMsgs);
            const evaluationId = selectChat?.evaluationId
            // Extract message IDs
            const quotedMessageIds = selectedMsgs.map((msg) => msg.message_id);
            // const quotedMessagesText = selectedMsgs.map((msg) => msg.message);
            console.log("Selected Message IDs:", quotedMessageIds);

            // Prepare the payload
            const payload = {
                status: modalAction === "approve" ? "APPROVED" : "REJECTED",
                quoted_message_ids: quotedMessageIds,
                // remarks: "" // Optional remarks if needed
            };

            console.log("Payload:", payload);

            // Call the API function and pass the payload
            const response = await submitEvalutorApproveReject(payload, evaluationId); // Ensure `someIdVariable` is available
            console.log("Response from API:", response);

            // Clear modal and selection state
            setModalOpen(false);
            setShowCheckboxes(!showCheckboxes);
            setSelectedMessages({});
            setActionMessages([]);
            setModalAction("");


        } catch (error) {
            // Handle errors
            console.error("Error in handleConfirmAction:", error);
            alert("Something went wrong while completing the action.");
        }
    };

    // const transformedMessages = chatData.messages.map((msg, index) => ({
    //     position: msg.sender === "me" ? "right" : "left",
    //     type: "text",
    //     text: (
    //         <Box sx={{ display: "flex", alignItems: "center" }}>
    //             {/* <Box sx={{ display: "flex", alignItems: "center", backgroundColor: msg.sender === "me" ? "#e0f7fa" : "#ffffff", padding: "8px", borderRadius: "8px", marginBottom: "8px" }} > */}
    //             {msg.sender !== "me" && showCheckboxes && (
    //                 <Checkbox
    //                     checked={!!selectedMessages[index]}
    //                     onChange={() => handleCheckboxChange(index)}
    //                     sx={{ marginRight: 1 }}
    //                 />
    //             )}
    //             <div className="message-content" dangerouslySetInnerHTML={{ __html: msg.text }} />
    //         </Box>
    //     ),
    //     date: new Date(msg.dateTime)
    // }));

    // const transformedMessages =
    //     chatData?.chat_history?.map((msg, index) => ({
    //         position: msg.sender === "Evaluator" ? "right" : "left",
    //         type: "text",
    //         text: (
    //             <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
    //                 {msg.sender !== "Evaluator" && showCheckboxes && (
    //                     <Checkbox
    //                         checked={!!selectedMessages[index]}
    //                         onChange={() =>
    //                             setSelectedMessages((prev) => ({
    //                                 ...prev,
    //                                 [index]: !prev[index],
    //                             }))
    //                         }
    //                     />
    //                 )}
    //                 {/* <Typography>{msg.message}</Typography> */}
    //                 <div className="message-content" dangerouslySetInnerHTML={{ __html: msg.message }} />
    //             </Box>
    //         ),
    //         date: new Date(msg.timestamp),
    //     })) || [];

    // const transformedMessages =
    //     // chatData
    //     selectedChat?.chat_history?.map((msg, index) => ({
    //         position: msg.sender === "Evaluator" ? "right" : "left",
    //         type: "text",
    //         text: (
    //             <Box
    //                 sx={{
    //                     display: "flex",
    //                     alignItems: "center",
    //                     // justifyContent: "center",
    //                     justifyContent: msg.sender === "Evaluator" ? "flex-end" : "flex-start",
    //                     mb: 1
    //                     // flexDirection: msg.sender === "Evaluator" ? "row-reverse" : "row"
    //                 }}
    //             >
    //                 {/* Checkbox (conditionally rendered) */}
    //                 {showCheckboxes && (
    //                     <Grow in={showCheckboxes} timeout={800}>
    //                         <Checkbox
    //                             checked={!!selectedMessages[index]}
    //                             onChange={() =>
    //                                 setSelectedMessages((prev) => ({
    //                                     ...prev,
    //                                     [index]: !prev[index]
    //                                 }))
    //                             }
    //                         />
    //                     </Grow>
    //                 )}
    //                 {/* Avatar */}
    //                 {msg.sender === "Evaluator" ? (
    //                     <MuiAvatar
    //                         sx={{
    //                             top: 3,
    //                             width: 30,
    //                             height: 30,
    //                             backgroundColor: "#ff5722", // Orange for Evaluator
    //                             fontSize: 15,
    //                             // mr: msg.sender === "Evaluator" ? 0.5 : 0,
    //                             // ml: msg.sender !== "Evaluator" ? 2 : 0,
    //                             ml: 1
    //                         }}
    //                     >
    //                         AB
    //                     </MuiAvatar>
    //                 ) : (
    //                     <MuiAvatar
    //                         sx={{
    //                             top: 4,
    //                             width: 30,
    //                             height: 30,
    //                             backgroundColor: "#1976d2", // Blue for others
    //                             fontSize: 15,
    //                             // mr: msg.sender === "Evaluator" ? 2 : 0,
    //                             ml: msg.sender !== "Evaluator" ? 1 : 0,
    //                             mr: 1
    //                         }}
    //                     >
    //                         RF
    //                     </MuiAvatar>
    //                 )}
    //                 {/* Message Content */}
    //                 {/* <div className="message-content" dangerouslySetInnerHTML={{ __html: msg.message }} /> */}
    //                 <MessageBox position={msg.sender === "Evaluator" ? "right" : "left"}>
    //                     {" "}
    //                     <div
    //                         className="message-content"
    //                         dangerouslySetInnerHTML={{ __html: msg.message }}
    //                     />{" "}
    //                     {/* <Typography
    //                         variant="caption"
    //                         sx={{
    //                             display: "block",
    //                             mt: 0.5,
    //                             textAlign: msg.sender === "Evaluator" ? "right" : "left",
    //                             color: "#888"
    //                         }}
    //                     >
    //                         {" "}
    //                         {new Date(msg.timestamp).toLocaleString()}{" "}
    //                     </Typography>{" "} */}
    //                 </MessageBox>
    //             </Box>
    //         ),
    //         date: new Date(msg.timestamp)
    //     })) || [];

    const sortedChatHistory = [...(chatHistory?.chat_history || [])].sort(
        (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
    );

    // const transformedMessages2 =
    //     // chatData
    //     chatHistory?.chat_history?.map((msg, index) => ({
    //         position: msg.sender === "AUTHORITY" ? "right" : "left",
    //         type: "text",
    //         text: (
    //             <Box
    //                 sx={{
    //                     display: "flex",
    //                     alignItems: "center",
    //                     // justifyContent: "center",
    //                     justifyContent: msg.sender === "AUTHORITY" ? "flex-end" : "flex-start",
    //                     mb: 1
    //                     // flexDirection: msg.sender === "Evaluator" ? "row-reverse" : "row"
    //                 }}
    //             >
    //                 {/* Checkbox (conditionally rendered) */}
    //                 {showCheckboxes && (
    //                     <Grow in={showCheckboxes} timeout={800}>
    //                         <Checkbox
    //                             checked={!!selectedMessages[index]}
    //                             onChange={() =>
    //                                 setSelectedMessages((prev) => ({
    //                                     ...prev,
    //                                     [index]: !prev[index]
    //                                 }))
    //                             }
    //                         />
    //                     </Grow>
    //                 )}
    //                 {/* Avatar */}
    //                 {msg.sender === "AUTHORITY" ? (
    //                     <MuiAvatar
    //                         sx={{
    //                             top: 3,
    //                             width: 30,
    //                             height: 30,
    //                             backgroundColor: "#ff5722", // Orange for Evaluator
    //                             fontSize: 15,
    //                             // mr: msg.sender === "Evaluator" ? 0.5 : 0,
    //                             // ml: msg.sender !== "Evaluator" ? 2 : 0,
    //                             ml: 1
    //                         }}
    //                     >
    //                         AB
    //                     </MuiAvatar>
    //                 ) : (
    //                     <MuiAvatar
    //                         sx={{
    //                             top: 4,
    //                             width: 30,
    //                             height: 30,
    //                             backgroundColor: "#1976d2", // Blue for others
    //                             fontSize: 15,
    //                             // mr: msg.sender === "Evaluator" ? 2 : 0,
    //                             ml: msg.sender !== "AUTHORITY" ? 1 : 0,
    //                             mr: 1
    //                         }}
    //                     >
    //                         RF
    //                     </MuiAvatar>
    //                 )}
    //                 {/* Message Content */}
    //                 {/* <div className="message-content" dangerouslySetInnerHTML={{ __html: msg.message }} /> */}
    //                 <MessageBox position={msg.sender === "AUTHORITY" ? "right" : "left"}>
    //                     {" "}
    //                     <div
    //                         className="message-content"
    //                         dangerouslySetInnerHTML={{ __html: msg.message }}
    //                     />{" "}
    //                     {/* <Typography
    //                         variant="caption"
    //                         sx={{
    //                             display: "block",
    //                             mt: 0.5,
    //                             textAlign: msg.sender === "Evaluator" ? "right" : "left",
    //                             color: "#888"
    //                         }}
    //                     >
    //                         {" "}
    //                         {new Date(msg.timestamp).toLocaleString()}{" "}
    //                     </Typography>{" "} */}
    //                 </MessageBox>
    //             </Box>
    //         ),
    //         date: new Date(msg.timestamp)
    //     })) || [];

    const transformedMessages2 = sortedChatHistory.map((msg, index) => ({

        // position: msg.sender === "AUTHORITY" ? "right" : "left",
        align: msg.sender === "AUTHORITY" ? "flex-start" : "flex-end",
        type: "text",
        text: (
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: msg.sender === "AUTHORITY" ? "flex-end" : "flex-start",
                    flexDirection: msg.sender === "AUTHORITY" ? "row" : "row-reverse",
                    mb: 1,
                }}
            >
                {/* Checkbox */}
                {showCheckboxes && (
                    <Grow in={showCheckboxes} timeout={800}>
                        <Checkbox
                            checked={!!selectedMessages[index]}
                            onChange={() => {
                                // console.log("msg", msg)
                                setSelectedMessages((prev) => ({
                                    ...prev,
                                    [index]: !prev[index],
                                }))

                            }

                            }
                        />
                    </Grow>
                )}
                {/* Avatar */}
                <MuiAvatar
                    sx={{
                        width: 30,
                        height: 30,
                        backgroundColor: msg.sender === "AUTHORITY" ? "#ff5722" : "#1976d2",
                        fontSize: 15,
                        ml: msg.sender !== "AUTHORITY" ? 1 : 0,
                        mr: msg.sender === "AUTHORITY" ? 1 : 0,
                    }}
                >
                    {msg.sender === "AUTHORITY" ? "AB" : "RF"}
                </MuiAvatar>
                {/* Message Content */}
                <MessageBox position={msg.sender === "AUTHORITY" ? "right" : "left"}>
                    <div
                        className="message-content"
                        dangerouslySetInnerHTML={{ __html: msg.message }}
                    />
                </MessageBox>
            </Box>
        ),
        date: new Date(msg.timestamp),
    }));






    return (
        <>
            {/* <ResponsiveAppBar title="QC" /> */}
            <Stack direction="row" height="100vh" flex="0.3">
                {/* Chat List */}
                <Box
                    sx={{
                        // width: "25%",
                        borderRight: "1px solid #ccc",
                        overflowY: "auto",
                        padding: "16px"
                    }}
                >
                    <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ marginBottom: "10px" }}>

                        <a href="https://applications.wes.org/createaccount/login/login" target="_blank" rel="noopener noreferrer">
                            <img src="/assets/logo/logo2.svg" alt="UBV Logo" style={{ height: "50px" }} />
                        </a>
                        <Typography variant="h6">
                            Applications
                        </Typography>

                        <Button variant="outlined" >
                            Logout
                        </Button>
                    </Stack>

                    <TextField
                        variant="outlined"
                        fullWidth
                        placeholder="Search"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                            style: {
                                // padding: "10px 14px", // Adjust inner padding here
                            }
                        }}
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                borderRadius: "10px", // Rounded corners
                                marginBottom: "10px"
                            }
                        }}
                        onChange={handleSearchChange}
                    />
                    <ChatList
                        dataSource={filteredChatItems}
                        onClick={(chat) => handleChatClick(chat?.application_id)}

                    />
                </Box>

                {/* <Box sx={{ width: "25%", borderRight: "1px solid #ccc", overflowY: "auto" }}>
                <ChatList dataSource={chatItems}
                    onClick={chat => handleChatClick(chat.application_id)}
                />
            </Box> */}
                <Box sx={{ display: "flex", flexDirection: "column", height: "100vh", flex: "1" }}>
                    <Box onClick={handleProfileToggle} sx={{ position: "relative", cursor: "pointer" }}>
                        <Navbar
                            left={
                                <>
                                    {/* <IconButton
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            navigate(-1);
                                        }}
                                    >
                                        {" "}
                                        <ArrowBackIcon />{" "}
                                    </IconButton> */}
                                    <Avatar
                                        src="https://avatars.githubusercontent.com/u/80540635?v=4"
                                        alt="avatar"
                                        size="xlarge"
                                        type="rounded"
                                    />
                                    <Stack>
                                        <Box sx={{ fontWeight: "bold" }}>Application ID:   {selectChat?.evaluationId}</Box>
                                        {/* <Box sx={{ color: "gray", fontSize: "0.9rem" }}>
                                            Document: {chatData?.document_type}
                                        </Box> */}
                                        {/* <Box sx={{ color: "gray", fontSize: "0.9rem" }}>
                                    Bank: {chatData.authorityDetails.bankName}
                                </Box> */}
                                    </Stack>
                                </>
                            }
                            right={
                                // <Stack direction="row" justifyContent="center" alignItems="center">
                                //     <Typography>CheckBox</Typography>
                                <Box sx={{ marginRight: "20px" }}>
                                    {showCheckboxes ? (
                                        // <UnpublishedOutlinedIcon
                                        //     sx={{ fontSize: "2rem", cursor: "pointer" }}
                                        //     // onClick={() => setShowCheckboxes(!showCheckboxes)}
                                        //     onClick={(e) => {
                                        //         e.stopPropagation();
                                        //         handleToggleCheckboxes();
                                        //     }}
                                        // />
                                        <Button
                                            variant="contained"
                                            sx={{ cursor: "pointer" }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleToggleCheckboxes();
                                            }}
                                        >Close</Button>
                                    ) : (
                                        // <TaskAltOutlinedIcon
                                        //     sx={{ fontSize: "2rem", cursor: "pointer" }}
                                        //     // onClick={() => setShowCheckboxes(!showCheckboxes)}
                                        //     onClick={(e) => {
                                        //         e.stopPropagation();
                                        //         handleToggleCheckboxes();
                                        //     }}
                                        // />
                                        <Button
                                            variant="contained"
                                            sx={{ cursor: "pointer" }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleToggleCheckboxes();
                                            }}
                                        >Complete</Button>
                                    )}
                                </Box>
                                // </Stack>
                            }
                            type="light"
                        />
                    </Box>
                    {/* Chat Messages */}
                    {/* <Box sx={{ flexGrow: 1, overflowY: "auto", p: 2, borderBottom: "1px solid #ccc" }}> */}
                    {/* <MessageList
                        className="message-list"
                        lockable={true}
                        toBottomHeight={"100%"}
                        dataSource={transformedMessages}
                    /> */}
                    {/* <Box sx={{ flexGrow: 1, overflowY: "auto", p: 2, borderBottom: "1px solid #ccc" }}> {transformedMessages} </Box> */}
                    {/* <Box sx={{ flexGrow: 1, overflowY: "auto", p: 2, borderBottom: "1px solid #ccc" }}> {transformedMessages.map((msg, index) => (<Box key={index} sx={{ display: "flex", justifyContent: msg.position === "right" ? "flex-end" : "flex-start", mb: 1, }} > <Box sx={{ p: 2, borderRadius: 1, backgroundColor: msg.position === "right" ? "#e0f7fa" : "#f1f1f1", maxWidth: "70%", }} > <div className="message-content" dangerouslySetInnerHTML={{ __html: msg.text }} /> <Box sx={{ mt: 1, fontSize: "0.75rem", color: "#888" }}> {msg.date.toLocaleString()} </Box> </Box> </Box>))} </Box> */}
                    {/* </Box> */}

                    <Box sx={{ flexGrow: 1, overflowY: "auto", p: 2, borderBottom: "1px solid #ccc" }}>
                        {" "}
                        {!selectChat?.action && transformedMessages2.map((msg, index) => (
                            <Box
                                key={index}
                                sx={{
                                    display: "flex",
                                    // flexDirection: msg.align === "flex-start" ? "row" : "row-reverse",
                                    justifyContent: msg.align,
                                }}
                            >
                                {" "}
                                {msg.text}{" "}
                            </Box>
                        ))}
                    </Box>

                    {selectChat?.action === "INITIATE_VALIDATION" && (
                        <Box sx={{ textAlign: "center", margin: "5px" }}>
                            <Button
                                variant="contained"
                                // onClick={() => setIsTextAreaEnabled(true)}
                                sx={{ backgroundColor: "black", color: "white" }}
                            >
                                Initiate
                            </Button>
                        </Box>
                    )}

                    {selectChat?.action === "AUTHORITY_UPDATE_REQUIRED" && (
                        <Box sx={{ textAlign: "center", margin: "5px" }}>
                            <Button
                                variant="contained"
                                // onClick={() => setIsTextAreaEnabled(true)}
                                sx={{ backgroundColor: "black", color: "white" }}
                            >
                                Authority Update Required
                            </Button>
                        </Box>
                    )}

                    {/* Text Editor or Action Buttons */}
                    {/* <Box sx={{ display: "flex", alignItems: "center", p: 2, borderTop: "1px solid #ccc" }}> */}
                    <Box>
                        {Object.values(selectedMessages).some((isSelected) => isSelected) ? (
                            <Box sx={{ display: "flex", flexGrow: 1, justifyContent: "center", p: 2 }}>
                                <Button
                                    variant="contained"
                                    // color="success"
                                    sx={{ marginRight: 2 }}
                                    // onClick={() => {
                                    //     const selectedMsgs = chatData.messages.filter((_, idx) => selectedMessages[idx]);
                                    //     selectedMsgs.forEach((msg) => handleAction(msg, "approve"));

                                    // }}
                                    onClick={() => handleAction("approve")}
                                >
                                    Approve
                                </Button>
                                <Button
                                    variant="contained"
                                    color="error"
                                    // onClick={() => {
                                    //     const selectedMsgs = chatData.messages.filter((_, idx) => selectedMessages[idx]);
                                    //     selectedMsgs.forEach((msg) => handleAction(msg, "reject"));
                                    // }}
                                    onClick={() => handleAction("reject")}
                                >
                                    Reject
                                </Button>
                            </Box>
                        ) :
                            (
                                // transformedMessages2?.length > 0 && !selectChat?.action && (
                                //     <Paper
                                //         elevation={1}
                                //         sx={{
                                //             display: "flex",
                                //             alignItems: "center",
                                //             padding: "8px",
                                //             // borderRadius: "30px",
                                //             boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)"
                                //             // border: "1px solid #e0e0e0",
                                //         }}
                                //     >
                                //         {/* Custom Toolbar on the Left */}
                                //         <Box
                                //             sx={{
                                //                 display: "flex",
                                //                 flexDirection: "row",
                                //                 alignItems: "center",
                                //                 gap: "8px",
                                //                 marginRight: "10px"
                                //             }}
                                //             id="custom-toolbar"
                                //         >
                                //             <button className="ql-bold" style={{ border: "none", padding: 0 }} />
                                //             <button className="ql-italic" style={{ border: "none", padding: 0 }} />
                                //             <button className="ql-underline" style={{ border: "none", padding: 0 }} />
                                //         </Box>

                                //         {/* Typing Area */}
                                //         <Box
                                //             sx={{
                                //                 flexGrow: 1
                                //             }}
                                //         >
                                //             <ReactQuill
                                //                 theme="snow"
                                //                 ref={quillRef}
                                //                 value={messageContent}
                                //                 onChange={setMessageContent}
                                //                 modules={{
                                //                     toolbar: {
                                //                         container: "#custom-toolbar" // Link to the custom toolbar
                                //                     }
                                //                 }}
                                //                 onKeyDown={(e) => {
                                //                     if (e.key === "Enter" && !e.shiftKey) {
                                //                         e.preventDefault(); // Prevent new line
                                //                         handleSendMessage(selectChat?.evaluationId); // Trigger send
                                //                     }
                                //                 }}
                                //                 formats={["bold", "italic", "underline"]}
                                //                 placeholder="Type a message..."
                                //                 style={{
                                //                     border: "none",
                                //                     outline: "none",
                                //                     fontSize: "16px",
                                //                     width: "100%"
                                //                 }}
                                //             />
                                //         </Box>

                                //         {/* Send Button */}
                                //         <IconButton
                                //             color="primary"
                                //             sx={{
                                //                 marginLeft: "8px"
                                //             }}
                                //         >
                                //             <SendIcon onClick={() => handleSendMessage(selectChat?.evaluationId)} />
                                //         </IconButton>
                                //     </Paper>
                                // )
                                (transformedMessages2?.length > 0 || !selectChat?.action) && (
                                    <Paper
                                        elevation={1}
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            padding: "8px",
                                            boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)"
                                        }}
                                    >
                                        {/* Custom Toolbar on the Left */}
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                gap: "8px",
                                                marginRight: "10px"
                                            }}
                                            id="custom-toolbar"
                                        >
                                            <button className="ql-bold" style={{ border: "none", padding: 0 }} />
                                            <button className="ql-italic" style={{ border: "none", padding: 0 }} />
                                            <button className="ql-underline" style={{ border: "none", padding: 0 }} />
                                        </Box>

                                        {/* Typing Area */}
                                        <Box
                                            sx={{
                                                flexGrow: 1
                                            }}
                                        >
                                            <ReactQuill
                                                theme="snow"
                                                ref={quillRef}
                                                value={messageContent}
                                                onChange={setMessageContent}
                                                modules={{
                                                    toolbar: {
                                                        container: "#custom-toolbar" // Link to the custom toolbar
                                                    }
                                                }}
                                                onKeyDown={(e) => {
                                                    if (e.key === "Enter" && !e.shiftKey) {
                                                        e.preventDefault(); // Prevent new line
                                                        handleSendMessage(selectChat?.evaluationId); // Trigger send
                                                    }
                                                }}
                                                formats={["bold", "italic", "underline"]}
                                                placeholder="Type a message..."
                                                style={{
                                                    border: "none",
                                                    outline: "none",
                                                    fontSize: "16px",
                                                    width: "100%"
                                                }}
                                            />
                                        </Box>

                                        {/* Send Button */}
                                        <IconButton
                                            color="primary"
                                            sx={{
                                                marginLeft: "8px"
                                            }}
                                        >
                                            <SendIcon onClick={() => handleSendMessage(selectChat?.evaluationId)} />
                                        </IconButton>
                                    </Paper>
                                )

                            )
                        }
                    </Box>
                    <Modal
                        open={isModalOpen}
                        onClose={() => setModalOpen(false)}
                        title={modalAction === "approve" ? "Accept Application" : "Reject Application"}
                        // content={`Are you sure you want to ${modalAction} the selected messages?`}
                        content={
                            <>
                                <Typography>Are you sure you want to {modalAction} this application?</Typography>
                                {/* <textarea
                                placeholder="Add remarks (optional)"
                                value={remarks}
                                onChange={(e) => setRemarks(e.target.value)}
                            /> */}
                                <TextField
                                    label="Add remarks (optional)"
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={3}
                                    value={remarks}
                                    onChange={(e) => setRemarks(e.target.value)}
                                    margin="normal"
                                />
                            </>
                        }
                        onConfirm={handleConfirmAction}
                    />
                </Box>
                {profileVisible && <StudentProfile data={selectChat?.evaluationId} />}
            </Stack>
        </>
    );
}

export default ChatWindow;
