import React from "react";
import MUIDataTable from "mui-datatables";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
// import DateFilter from "./DateFilter";
import BasicPopover from "./Popover";

function DataTable({ list, column, onDateChange, QCList, title }) {
    const navigate = useNavigate();
    const handleRowClick = (rowData, rowMeta) => {
        if (QCList) {
            const applicationId = rowData[0];
            navigate(`/qc/evaluation/${applicationId}`);
        } else {
            const referenceId = rowData[0];
            navigate(`/chat/${referenceId}`);
        }
    };
    const options = {
        // onRowClick: (rowData, rowMeta) => {
        //     const referenceId = rowData[1]; // Assuming ReferenceId is the second column
        //     // console.log("refe", referenceId)
        //     navigate(`/chat/${referenceId}`);
        // },
        onRowClick: handleRowClick,
        setRowProps: () => ({ style: { cursor: "pointer" } }),
        selectableRows: "none",
        download: false,
        viewColumns: false,
        print: false,
        // customToolbar: () => <BasicPopover onDateChange={onDateChange} />,
        // customToolbar: () => (
        //     <div style={{ display: "flex", alignItems: "flex-start" }}>

        //         <BasicPopover onDateChange={onDateChange} />
        //     </div>
        // ),

        onFilterChange: (changedColumn, filterList) => {
            if (changedColumn === "SrNo" || changedColumn === "ReferenceId" || changedColumn === "date" || changedColumn === "application_id" || changedColumn === "user_name" || changedColumn === "submitted_at") {
                filterList.forEach((filter) => {
                    filter[column.findIndex((col) => col.name === changedColumn)] = [];
                });
            }
        }


    };

    const customColumns = column.map((column) => ({
        ...column,
        options: { ...column.options, filter: !["SrNo", "ReferenceId", "date", "application_id", "user_name", "submitted_at"].includes(column.name) }
    }));


    return (
        <>
            <MUIDataTable title={title} data={list} columns={column} options={options} />
        </>
    )
}

export default DataTable;
