import React, { useContext, useEffect, useState } from "react";
import { Box, Typography, TextField, Button } from "@mui/material";
import { FormContext } from "../../contexts/UserContext/FormContextProvider";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { UserBaseURL } from "app/contexts/JWTAuthContext";
import { loadScript } from "../../utils/utils";

const Step6Payment = () => {
    const { state, dispatch } = useContext(FormContext);
    const [promoCode, setPromoCode] = useState("");
    const userId = sessionStorage.getItem("userId");
    const token = sessionStorage.getItem("token");
    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);
    // Calculate payment details dynamically
    useEffect(() => {
        dispatch({
            type: "UPDATE_PAYMENT_BREAKDOWN",
            payload: {
                isCourierDelivery: state.recipientDetails.isCourierDelivery,
                promoCode: state.paymentInfo.promoCode,
            },
        });
    }, [state.recipientDetails.isCourierDelivery, state.paymentInfo.promoCode, dispatch]);

    const { paymentBreakdown } = state.paymentInfo;

    const handlePayment = async () => {
        const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')

        if (!res) {
            alert('Razropay failed to load!!')
            return
        }

        const data = await axios.post(`${UserBaseURL}/application/create-order`, {
            applicationId: state.applicantDetail.id || state.refNo || "4TBBAY",
            userId: userId,
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        })

        console.log("order response::", data)

        const options = {
            "key": process.env.REACT_APP_RAZORPAY_KEY_ID, // Enter the Key ID generated from the Dashboard
            "amount": data?.data?.data?.amount || 500000, // Amount is in currency subunits. Default currency is INR. Hence, 500000 refers to 500000 paise
            "currency": data?.data?.data?.currency || "INR",
            "name": "Universal Bank Validation",
            "description": "UBV Report Transaction",
            "image": "https://www.universalbankvalidation.com/wp-content/uploads/2024/11/email-logo.png",
            "order_id": data?.data?.data?.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
            "handler": async function (response) {
                // Called when the payment is successful
                console.log("Payment successful", response);

                // Call your POST API here
                handleNextStep(data);
            },
            "callback_url": 'http://localhost:3000/api/application/verify-payment',
            "notes": {
                "address": "Universal Bank Validation"
            },
            "theme": {
                "color": "#3399cc"
            }
        };
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    };

    const handleApplyPromoCode = () => {
        dispatch({
            type: "UPDATE_PAYMENT_INFO",
            payload: { promoCode: promoCode },
        })
    }

    const handleNextStep = async (order) => {
        console.log("step6 payload:", { applicationId: state.applicantDetail.id, ...state.paymentInfo, userId })
        // dispatch({ type: "UPDATE_STEP", payload: state.step + 1 });
        let payload = {
            applicationId: order?.data?.data?.receipt || state.applicantDetail.id || state.refNo,
            userId: userId,
            orderId: order?.data?.data?.id || "ORD1234567890",
            promoCode: promoCode,
            // paymentStatus: "COMPLETED",
            // amount: order?.data?.data?.amount || state.paymentInfo.paymentBreakdown.total,
            // currency: order?.data?.data?.currency || "INR",
            // paymentMethod: "CREDIT CARD",
            // transactionId: "4ba5aaf6-e5d2-41fd-8c2c-f35e98576ece",

        }
        try {
            await axios.post(`${UserBaseURL}/application/payment-details`, payload, { headers: { Authorization: `Bearer ${token}` } }).then((res) => {
                console.log("step6 response:", res.data);
                dispatch({ type: "RESET_FORM" });
                navigate("/user/dashboard");
                // if (searchParams.get("edit")) {
                //     dispatch({ type: "UPDATE_APPLICANT", payload: res.data });
                //     dispatch({ type: "UPDATE_STEP", payload: 4 });
                //     navigate("/user/application-form", { replace: true });
                // } else {
                //     // api call here post
                //     dispatch({ type: "UPDATE_APPLICANT", payload: res.data });
                //     dispatch({ type: "UPDATE_STEP", payload: state.step + 1 });
                // }
            }).catch((err) => {
                console.error("Error saving data:", err.response);
                // alert("Failed to save data. Please try again.");
            });
        } catch (error) {
            console.error("Error:", error);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        if (searchParams.get("refNo")) {
            navigate(`${location.pathname}${location.search}`, { replace: true })
        }
    }, [])

    console.log("state6:", state)

    return (
        <Box sx={{ width: "100%", p: 2 }}>
            <Typography variant="h6">Payment Details</Typography>
            <Typography>Report Charges: ${paymentBreakdown?.reportCharge || 0}</Typography>
            <Typography>
                Courier Charges: ${paymentBreakdown?.courierCharge || 0}
            </Typography>
            <Typography>Discount: -${paymentBreakdown?.discount || 0}</Typography>
            <Typography>
                <strong>Total: ${paymentBreakdown?.total || 0}</strong>
            </Typography>
            <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                <TextField
                    label="Promo Code"
                    value={promoCode}
                    onChange={(e) =>
                        // dispatch({
                        //     type: "UPDATE_PAYMENT_INFO",
                        //     payload: { promoCode: e.target.value },
                        // })
                        setPromoCode(e.target.value)
                    }
                    margin="normal"
                    size="small"
                />
                <Button
                    variant={promoCode ? "contained" : "text"}
                    // onClick={handlePayment}
                    // onClick={handleApplyPromoCode}
                    size="medium"
                    color="success"
                    sx={{ height: "100%", marginTop: "8px" }}
                >
                    Apply
                </Button>
            </Box>
            <Box sx={{ mt: 2, mx: 0, display: "flex", gap: 2 }}>
                <Button
                    disabled={state.step === 0}
                    variant="outlined"
                    onClick={() => navigate("/user/dashboard")}
                // onClick={() => dispatch({ type: "UPDATE_STEP", payload: state.step - 1 })}
                >
                    Back
                </Button>
                <Button
                    variant="contained"
                    // onClick={() =>
                    // (dispatch({ type: "UPDATE_STEP", payload: Math.min(state.step + 1, steps.length - 1) }),
                    //     state.step === steps.length - 1 && navigate('/dashboard'))
                    // }
                    onClick={handlePayment}
                >
                    {"Pay"}
                </Button>
            </Box>

        </Box>
    );
};

export default Step6Payment;
