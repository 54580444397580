import React, { useState, Fragment, useEffect } from 'react';
import {
    Card, CardContent, Typography, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow, Checkbox, IconButton,
    TablePagination, TableSortLabel, styled, Box, Button, TextField, Menu, MenuItem, InputLabel, FormControl, Select, Dialog, DialogContent
} from '@mui/material';
import { FilterList, Done, Clear, TrendingFlat, Start, RemoveRedEye, Send } from '@mui/icons-material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import HeaderUBV from 'app/components/HeaderUBV';
import Swal from "sweetalert2";
import axios from "axios";
import { UserBaseURL } from "app/contexts/JWTAuthContext";
import moment from 'moment/moment';
import { DatePicker } from "antd";


const { RangePicker } = DatePicker;


const StyledRoot = styled("div")(() => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#76B3D8",
    minHeight: "100%",
    padding: "1rem",
    marginTop: "-80px",
    // marginTop: "5rem",
    "& .card-container": {
        marginTop: "85px",
        width: "100%",
        // maxWidth: "1200px",
        backgroundColor: "rgba(288, 288, 288, 0.9)",
        // backgroundColor: "#e9e9e9",
        padding: "1rem",
        boxSizing: "border-box",
    },
    "& .nodata-card": {
        width: "1400px",
        height: "300px",
        marginBottom: "1rem",
        padding: "1rem",
        // backgroundColor: "#fff",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // flexDirection: "column",
        // boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        // border: "1px solid rgba(0, 0, 0, 0.1)",
        // borderRadius: "8px",
    },
    "& .application-card": {
        width: "100%",
        marginBottom: "1rem",
        padding: "1rem",
        backgroundColor: "#fff",
        display: "flex",
        flexDirection: "column",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
    },
    "& .card-header": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: "wrap", // Wrap on smaller screens
        gap: "1rem",
        marginBottom: "2rem"
    },
    "& .status": {
        padding: "4px 8px",
        borderRadius: "4px",
        color: "#fff",
    },
    "@media (max-width: 768px)": {
        padding: "1rem",
        marginTop: "2rem",
        "& .card-header": {
            // flexDirection: "column", // Stack on smaller screens
            // alignItems: "flex-start",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        },
        "& .application-card": {
            padding: "0.8rem",
        },
        "& .card-container": {
            padding: "0.5rem",
        },
    },
    "@media (max-width: 480px)": {
        "& .card-header": {
            alignItems: "stretch",
        },
        "& .application-card": {
            padding: "0.5rem",
        },
    },
}));

const MainPage = () => {
    const token = sessionStorage.getItem("token");
    const instituteId = sessionStorage.getItem("userId");
    const [totalCount, setTotalCount] = useState(0);
    const [orders1, setOrders1] = useState([]);
    const [filterStatus, setFilterStatus] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [anchorEl, setAnchorEl] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [iframeSrc, setIframeSrc] = useState('');
    const [dateRange, setDateRange] = useState([null, null]);
    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };
    const handleDateChange = (dates) => {
        console.log(dates.length)
        if (dates[0] === null && dates[1] === null) {
            setDateRange(dates, "onCalendarChange");
        } else {
            setDateRange(dates?.map((v) => moment(new Date(v)).format("DD-MM-YYYY")),
                "onCalendarChange");
        }


    };

    const handleStatusChange = (status) => {
        setFilterStatus(status);
        setAnchorEl(null);
    };
    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleStatusChange1 = (orderId, status) => {
        setOrders1((prevOrders) =>
            prevOrders.map((order) =>
                order.id === orderId ? { ...order, status } : order
            )
        );
    };
    const handleOpenDialog = async (src, applicationId, data) => {
        // setIframeSrc(src);
        // setOpenDialog(true);
        let aTag = document.createElement("a");
        await axios.get(`${UserBaseURL}/institutes/${instituteId}/applications/${applicationId}`).then((res) => {
            // aTag.href = res.data.data
            const fileUrl = res.data.data; // Assuming the API returns the URL directly

            if (fileUrl) {
                const aTag = document.createElement("a");
                // aTag.href = fileUrl;
                aTag.href = data.s3Link;
                aTag.target = "_blank"; // Opens the link in a new tab
                aTag.rel = "noopener noreferrer"; // Improves security for new tabs
                document.body.appendChild(aTag); // Append to DOM
                aTag.click(); // Trigger the click
                document.body.removeChild(aTag); // Clean up
            } else {
                console.error("File URL is missing from the API response.");
            }
        }).catch((err) => {
            console.error("Error fetching application file URL:", err);

        })
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setIframeSrc('');
    };
    // const filteredData = orders1.filter((row) => {
    //     return (
    //         (filterStatus === '' || row.status === filterStatus) &&
    //         (searchTerm === '' || row.referenceId.toLowerCase().includes(searchTerm.toLowerCase()))
    //     );
    // });
    const getAllApplications = async () => {
        await axios.get(`${UserBaseURL}/institutes/applications`, {
            headers: { Authorization: `Bearer ${token}` },
            params: { page: page, limit: rowsPerPage, search: searchTerm, fromDate: dateRange[0], toDate: dateRange[1] }
        }).then((res) => {
            // console.log("applications:", res.data);
            let data = res.data.data.map((ele, id) => ({
                srNo: id + 1,
                referenceId: ele.application_id,
                applicantId: ele.application.userId,
                // document: "Bank statement",
                // status: "Pending",
                doctype: ele.application.documentType === "BANK_STATEMENT" ? "Bank Statement" : ele.application.documentType === "BALANCE_CONFIRMATION" ? "Balance Confirmation" : "Both",
                fileKey: ele.application.fileKey,
                s3Link: "/institute/report?link=s3.uat.repiksk-knkhsjs-bjjb",
                // reportStatus:
                date: moment(ele.application.submittedAt).format("DD-MM-YYYY")

            }))
            setOrders1(data);
            setTotalCount(res.data.total)
        }).catch((err) => {
            // setApplications(transformData(dummyArr))
            console.log(err);
        })
    }
    useEffect(() => {
        if (searchTerm === "") {
            getAllApplications();
        } else {
            const DebounceFunc = setTimeout(() => {
                getAllApplications();
            }, 1000);

            return () => clearTimeout(DebounceFunc);
        }
    }, [page, rowsPerPage, searchTerm, dateRange])
    console.log("range:", dateRange);
    return (
        <Fragment>
            <HeaderUBV />
            <StyledRoot>

                <Box className="card-container">
                    {/* <Box className="card-header">
                        <Typography variant="h4">My Applications</Typography>
                        <Button variant="contained" color="primary">
                            Create New Application
                        </Button>
                    </Box> */}
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1rem' }}>
                        <TextField
                            label="Search by RefID"
                            variant="outlined"
                            value={searchTerm.toUpperCase()}
                            onChange={handleSearch}
                            sx={{ width: '25%' }}
                        />

                        {/* <TextField
                            label="Filter by Date"
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                            value={filterDate}
                            onChange={handleDateChange}
                            sx={{ width: '40%' }}
                        /> */}
                        <Box sx={{ width: "25%", height: "54px" }}>
                            <RangePicker style={{ width: "100%", height: "100%" }} onCalendarChange={handleDateChange} />
                        </Box>
                        {/* <DateRangePicker
                            startText="Start Date"
                            endText="End Date"
                            value={dateRange}
                            onChange={handleDateChange}
                            renderInput={(startProps, endProps) => (
                                <>
                                    <TextField {...startProps} sx={{ width: 150, marginRight: 2 }} />
                                    <TextField {...endProps} sx={{ width: 150 }} />
                                </>
                            )}
                        /> */}

                        {/* <Box>
                            <IconButton onClick={handleMenuOpen}>
                                <FilterList />
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                            >
                                <MenuItem onClick={() => handleStatusChange('')}>All</MenuItem>
                                <MenuItem onClick={() => handleStatusChange('Pending')}>Pending</MenuItem>
                                <MenuItem onClick={() => handleStatusChange('Approved')}>Approved</MenuItem>
                                <MenuItem onClick={() => handleStatusChange('Rejected')}>Rejected</MenuItem>
                            </Menu>
                        </Box> */}
                    </Box>
                    <Card >
                        <CardContent>
                            {/* <div>
                                <Typography variant="h6">All Applications</Typography>
                            </div> */}
                            <TableContainer sx={{ height: "500px", maxHeight: '400px', overflowY: 'auto' }}>
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center"><TableSortLabel>Sr No</TableSortLabel></TableCell>
                                            <TableCell align="center"><TableSortLabel>Ref Id</TableSortLabel></TableCell>
                                            <TableCell align="center"><TableSortLabel>Document</TableSortLabel></TableCell>
                                            {/* <TableCell align="center">
                                                <TableSortLabel>Status</TableSortLabel>
                                            </TableCell> */}
                                            <TableCell align="center">
                                                <TableSortLabel>Date</TableSortLabel>
                                            </TableCell>
                                            <TableCell align="center"><TableSortLabel>Report Status</TableSortLabel></TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {orders1.length === 0 ?
                                            <Box className="nodata-card" >
                                                <img src={"/assets/ubv-images/images/file.png"} alt="no-data" />
                                            </Box>
                                            :
                                            // filteredData
                                            //     .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            orders1.map((order, idx) => (
                                                <TableRow key={order.id}>
                                                    {/* <TableCell padding="checkbox">
                                            <Checkbox />
                                        </TableCell> */}
                                                    <TableCell align="center">{order.srNo}</TableCell>
                                                    <TableCell align="center">{order.referenceId}</TableCell>
                                                    <TableCell align="center">{order.doctype}</TableCell>
                                                    {/* <TableCell align="center"><span
                                                        style={{
                                                            backgroundColor:
                                                                order.status === 'Approved'
                                                                    // ? 'rgba(9, 182, 109, 1)'
                                                                    ? "#28a745"
                                                                    : order.status === 'Rejected'
                                                                        // ? '#FF3D57'
                                                                        ? "#dc3545" : order.status === "Under Review" ? "#ffc107" : "#ffc107",
                                                            // : '#FFAF38',
                                                            padding: '4px 8px',
                                                            borderRadius: '4px',
                                                            color: '#fff',
                                                        }}
                                                    >
                                                        {order.status}
                                                    </span></TableCell> */}
                                                    <TableCell align="center">{order.date}</TableCell>
                                                    <TableCell align="center">

                                                        <span
                                                            style={{
                                                                textAlign: "center",
                                                                backgroundColor:
                                                                    '#007bff',
                                                                padding: '4px 8px',
                                                                borderRadius: '4px',
                                                                color: '#fff',
                                                                // opacity: order.status === "Under Review" ? 0.5 : 1,
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                gap: "10px",
                                                                width: "50%",
                                                                margin: "0px 60px",
                                                                cursor: order.status === "Under Review" ? "no-drop" : "pointer"
                                                            }}
                                                            onClick={() => handleOpenDialog(order?.reportFile, order.referenceId, order)}
                                                        >
                                                            <VisibilityIcon />
                                                            {"View"}
                                                            {/* {order.status === 'Approved'
                                                                ? "View"
                                                                : order.status === 'Rejected'
                                                                    ? "Send" : order.status === "Under Review" ? "View" : "Send"} */}
                                                        </span>

                                                    </TableCell>

                                                </TableRow>
                                            ))}
                                    </TableBody>

                                </Table>
                            </TableContainer>

                            {/* Pagination */}
                            {orders1.length !== 0 && (<TablePagination
                                component="div"
                                count={totalCount}
                                page={page}
                                onPageChange={handleChangePage}
                                rowsPerPage={rowsPerPage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                rowsPerPageOptions={[5, 10, 25]}
                                labelRowsPerPage="Rows per page:"
                            />)}
                            <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="lg" fullWidth>
                                <DialogContent>
                                    <iframe
                                        src={iframeSrc}
                                        title="Report Viewer"
                                        style={{ width: '100%', height: '80vh', border: 'none' }}
                                    ></iframe>
                                </DialogContent>
                            </Dialog>
                        </CardContent>
                    </Card>
                </Box>
            </StyledRoot>
        </Fragment >
    )
}

export default MainPage