import { useRoutes, useNavigate, useLocation } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import React, { Suspense, useEffect } from 'react';
import { MatxTheme } from "./components";
// ALL CONTEXTS
import { AuthProvider } from "./contexts/JWTAuthContext";
import SettingsProvider from "./contexts/SettingsContext";
// ROUTES
import routes from "./routes";
// FAKE SERVER
import "../fake-db";

const UserDashboard =
  process.env.REACT_APP_ENABLE_USER === "true"
    ? React.lazy(() => import("./parent-components/UserDashboard"))
    : null;

const EvaluatorDashboard =
  process.env.REACT_APP_ENABLE_EVALUATOR === "true"
    ? React.lazy(() => import("./parent-components/EvaluatorDashboard"))
    : null;

const InstituteDashboard =
  process.env.REACT_APP_ENABLE_INSTITUTE === "true"
    ? React.lazy(() => import("./parent-components/InstituteDashboard"))
    : null;

export default function App() {
  const content = useRoutes(routes);
  const role = process.env.REACT_APP_ROLE;
  let RoleComponent;

  // if (role === 'admin') RoleComponent = AdminDashboard;
  if (role === 'user' || role === 'default') RoleComponent = UserDashboard;
  else if (role === 'evaluator') RoleComponent = EvaluatorDashboard;
  else if (role === 'institute') RoleComponent = InstituteDashboard;
  console.log("comp::", process.env.REACT_APP_ENABLE_EVALUATOR === "true");
  console.log("comp1::", role);
  const navigate = useNavigate();
  const location = useLocation();
  console.log("location app::", location.pathname.startsWith("/institute"));
  console.log("last path::", sessionStorage.getItem("lastPath"));
  // useEffect(() => {
  //   const lastPath = sessionStorage.getItem("lastPath");
  //   if (lastPath) {
  //     navigate(lastPath);
  //   }
  // }, [navigate]);
  useEffect(() => {
    if (location.search) {
      sessionStorage.setItem("lastPath", `${location.pathname}${location.search}`)
    } else {
      sessionStorage.setItem("lastPath", location.pathname);
    }

  }, [location]);
  useEffect(() => {
    const userRole = sessionStorage.getItem("userRole");
    const lastPath = sessionStorage.getItem("lastPath") || "/";
    if (userRole) {
      if (userRole === "INSTITUTE") {
        navigate(location.pathname.startsWith("/institute") ? lastPath : "/institute/dashboard");
      } else {
        // navigate(location.pathname.startsWith("/user") ? lastPath : "/user/dashboard");
        navigate("/user/dashboard");
      }
    } else {
      // if (location.pathname.startsWith("/institute")) {
      //   location.pathname === "/institute/report" ? navigate("/institute/report") : navigate("/institute/login");
      // } else {
      //   navigate("/session/signin");
      // }
      if (location.pathname.startsWith("/institute")) {
        if (location.pathname === "/institute/report") {
          // Preserve the current route and query parameters
          navigate(`${location.pathname}${location.search}`, { replace: true });
        } else {
          navigate("/institute/login");
        }
      } else {
        navigate("/session/signin");
      }
    }

  }, []);
  return (
    <SettingsProvider>
      <AuthProvider>
        <MatxTheme>
          {/* <CssBaseline />
          {content} */}
          <Suspense fallback={<div>Loading...</div>}>
            {RoleComponent ? <RoleComponent><CssBaseline />{content}</RoleComponent> : <div>No role matched!</div>}
          </Suspense>
        </MatxTheme>
      </AuthProvider>
    </SettingsProvider>
  );
}
