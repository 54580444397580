import React, { useState } from "react";
import DataTable from "app/components/DataTable";
import { Box, Container } from "@mui/material";
import Layout1Topbar from '../../components/MatxLayout/Layout1/Layout1Topbar'
import "./customStyles.css"
import ResponsiveAppBar from "app/components/Appbar";
import BasicPopover from "app/components/Popover";
import { formatDate } from "app/utils/utils";
import { EvaluatorFetch } from "dummy_data/data";

function DataTableList() {
    const [application, setApplication] = useState([
        {
            SrNo: 1,
            ReferenceId: "REF-8374GZPL",
            document: "BAnk statement",
            date: "29 Sep, 2024",
            status: "Pending",
            priority: "Low"
        },
        {
            SrNo: 2,
            ReferenceId: "REF-1942KQWY",
            document: "BAnk statement",
            date: "29 Sep, 2024",
            status: "Under Review",
            priority: "Medium"
        },
        {
            SrNo: 3,
            ReferenceId: "REF-5267MTVX",
            document: "BAnk statement",
            date: "29 Sep, 2024",
            status: "Pending",
            priority: "High"
        },
        {
            SrNo: 4,
            ReferenceId: "REF-3059NLRJ",
            document: "BAnk statement",
            date: "29 Sep, 2024",
            status: "Pending",
            priority: "Low"
        },
        {
            SrNo: 5,
            ReferenceId: "REF-7481BCXF",
            document: "BAnk statement",
            date: "29 Sep, 2024",
            status: "Under Review",
            priority: "Medium"
        },
        {
            SrNo: 6,
            ReferenceId: "REF-8374GZPL",
            document: "BAnk statement",
            date: "29 Sep, 2024",
            status: "Pending",
            priority: "Low"
        },
        {
            SrNo: 7,
            ReferenceId: "REF-1942KQWY",
            document: "BAnk statement",
            date: "29 Sep, 2024",
            status: "Under Review",
            priority: "Medium"
        },
        {
            SrNo: 8,
            ReferenceId: "REF-5267MTVX",
            document: "BAnk statement",
            date: "29 Sep, 2024",
            status: "Pending",
            priority: "High"
        },
        {
            SrNo: 9,
            ReferenceId: "REF-3059NLRJ",
            document: "BAnk statement",
            date: "29 Sep, 2024",
            status: "Pending",
            priority: "Low"
        },
        {
            SrNo: 10,
            ReferenceId: "REF-7481BCXF",
            document: "BAnk statement",
            date: "29 Sep, 2024",
            status: "Under Review",
            priority: "Medium"
        }
    ]);

    const [filterDate, setFilterDate] = useState({ fromDate: null, toDate: null });

    const handleDateChange = (dateRange) => { setFilterDate(dateRange); console.log('Selected Date Range:', dateRange); };

    const columns = [


        {
            name: "application_id",
            label: "Reference Id",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({ style: { textAlign: "center", paddingLeft: "0px" } }),
                setCellHeaderProps: () => ({ style: { textAlign: "center", paddingLeft: "0px", justifyContent: 'center', display: 'flex' } })
                // customBodyRender: (value) => (
                //   <div
                //     style={{
                //       wordBreak: "break-all",
                //       whiteSpace: "normal",
                //       width: "200px", // Adjust this width as needed
                //     }}
                //   >
                //     {value}
                //   </div>
                // ),
                // setCellProps: () => ({
                //   style: {
                //     width: "200px", // Adjust this width as needed
                //   },
                // }),
            }
        },
        {
            name: "document_type",
            label: "Document",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({ style: { paddingLeft: "60px" } }),
                setCellHeaderProps: () => ({ style: { paddingLeft: "60px" } })

            }
        },
        {
            name: "submitted_at",
            label: "Date",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => formatDate(value),
                setCellProps: () => ({ style: { paddingLeft: "50px" } }),
                setCellHeaderProps: () => ({ style: { paddingLeft: "50px" } })
                // customBodyRender: (value) => (
                //   <div
                //     style={{
                //       wordBreak: "break-all",
                //       whiteSpace: "normal",
                //       width: "180px", // Adjust this width as needed
                //     }}
                //   >
                //     {value}
                //   </div>
                // ),
                // setCellProps: () => ({
                //   style: {
                //     width: "180px", // Adjust this width as needed
                //   },
                // }),
            }
        },
        {
            name: "status",
            label: "Status",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => (
                    <span
                        style={{
                            display: "inline-block",
                            width: "110px",
                            backgroundColor:
                                value === "Pending"
                                    ? "rgba(9, 182, 109, 1)"
                                    : value === "In_Progress"
                                        ? "#FF3D57"
                                        : "#FFAF38",
                            padding: "4px 8px",
                            borderRadius: "4px",
                            color: "#fff",
                            textAlign: "center"
                        }}
                    >
                        {" "}
                        {value}{" "}
                    </span>
                )
            }
        },
        {
            name: "priority",
            label: "Priority",
            options: {
                filter: true,
                sort: true,

                customBodyRender: (value) => (
                    <span
                        style={{
                            display: "inline-block",
                            width: "100px",
                            backgroundColor:
                                value === "Low"
                                    ? "rgba(9, 182, 109, 1)" // Green
                                    : value === "Medium"
                                        ? "#FFAF38" // Orange
                                        : value === "High"
                                            ? "#FF3D57" // Red
                                            : "#ccc", // Default color
                            padding: "4px 8px",
                            borderRadius: "4px",
                            color: "#fff",
                            textAlign: "center"
                        }}
                    >
                        {value}
                    </span>
                )
            }
        }
    ];
    return (
        <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
            {/* AppBar Section */}
            <ResponsiveAppBar title="Evaluator" />

            {/* Content Section */}
            <Box
                sx={{
                    flex: 1,
                    backgroundColor: "#75b5d9",
                    pt: 3, // Padding-top for separation
                    pb: 3, // Padding-bottom for consistency
                }}
            >
                <Container>
                    <DataTable
                        list={EvaluatorFetch.evaluation_requests}
                        column={columns}
                        onDateChange={handleDateChange}
                        title="Applications"
                    />
                </Container>
            </Box>
        </Box>
    );
}

export default DataTableList;
