import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';

const DocumentViewer = ({ fileUrl }) => {
    const defaultFileUrl = '/udemy_certificate.pdf';
    const default2 = '/Aquib__Shaikh.pdf';
    const default3 = '/Invoice.pdf';


    // Initialize the toolbar plugin
    const toolbarPluginInstance = toolbarPlugin();
    const { Toolbar } = toolbarPluginInstance;

    console.log("fireUrlzzz", fileUrl)


    // useEffect(() => {
    //     let fileObjectUrl = null;

    //     const downloadFile = async () => {
    //         try {
    //             setLoading(true);
    //             const response = await fetch(fileUrl);
    //             const blob = await response.blob(); // Convert response to Blob
    //             fileObjectUrl = URL.createObjectURL(blob); // Create a temporary object URL
    //             setLocalFile(fileObjectUrl); // Set local file to state
    //         } catch (error) {
    //             console.error('Error downloading file:', error);
    //         } finally {
    //             setLoading(false);
    //         }
    //     };

    //     if (fileUrl) {
    //         downloadFile();
    //     }

    //     return () => {
    //         // Cleanup on unmount: Revoke the object URL to free memory
    //         if (fileObjectUrl) {
    //             URL.revokeObjectURL(fileObjectUrl);
    //             console.log('File object URL revoked');
    //         }
    //     };
    // }, [fileUrl]);

    return (
        <Box
            sx={{
                backgroundColor: '#f0f0f0', // Set background color to grey
                height: '100%', // Full height of the container
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            {/* Render the toolbar */}
            <Box sx={{ padding: '0.5rem', backgroundColor: '#ffffff' }}>
                <Toolbar />

            </Box>

            {/* Render the PDF Viewer */}
            <Box
                sx={{
                    flex: 1,
                    overflow: 'hidden', // Prevents unwanted scrollbars
                }}
            >
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                    <Viewer
                        // fileUrl={default2} // Replace this with `fileUrl` if passed dynamically
                        fileUrl={fileUrl}
                        plugins={[toolbarPluginInstance]} // Pass the toolbar plugin
                    />
                </Worker>

            </Box>
        </Box>
    );
};

export default DocumentViewer;
