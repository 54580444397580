import React, { useContext, useState } from "react";
import { Box, Typography, Button, Divider, FormControlLabel, Checkbox, Link, FormHelperText } from "@mui/material";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { FormContext } from "../../contexts/UserContext/FormContextProvider";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";

const Step5Review = ({ onEdit }) => {
    const { state, dispatch } = useContext(FormContext);
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const userId = sessionStorage.getItem("userId") || "23184724-ce70-43a5-a711-7a36ac80f62c";
    const [isChecked, setIsChecked] = useState({
        verifyDocuments: false,
        certifyInformation: false,
        acceptTerms: false,
        acknowledgePrivacyPolicy: false,
    });
    const [errors, setErrors] = useState({});
    const refNo = searchParams.get("refNo");
    const renderPurpose = () => (
        <Box sx={{ mb: 3 }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 1 }}>
                <Typography variant="h6" sx={{ fontWeight: 600 }}>
                    Application Purpose
                </Typography>
                <EditNoteIcon
                    onClick={() => onEdit(1, refNo ? `/user/application-form?edit=purpose&refNo=${refNo}` : "/user/application-form?edit=purpose")}
                    sx={{ cursor: "pointer", color: "primary.main" }}
                />
            </Box>
            <ul>
                {state.applicationPurpose.map((purpose, index) => (
                    <li key={index}>
                        <Typography variant="body1">{purpose}</Typography>
                    </li>
                ))}
                {state.applicationPurpose.includes("OTHER") && state.otherPurpose && (
                    <Typography variant="body1" sx={{ mt: 1 }}>
                        - Other Purpose: {state.otherPurpose}
                    </Typography>
                )}
            </ul>
            {/* <Box sx={{ mt: 2, mx: 0, display: "flex", gap: 2 }}>
                <Button
                    disabled={state.step === 0}
                    variant="outlined"
                    onClick={() => dispatch({ type: "UPDATE_STEP", payload: state.step - 1 })}
                >
                    Back
                </Button>
                <Button
                    variant="contained"
                    // onClick={() =>
                    // (dispatch({ type: "UPDATE_STEP", payload: Math.min(state.step + 1, steps.length - 1) }),
                    //     state.step === steps.length - 1 && navigate('/dashboard'))
                    // }
                    onClick={handleNextStep}
                >
                    {"Next"}
                </Button>
            </Box> */}
        </Box>
    );

    const renderDocuments = () => (
        <Box sx={{ mb: 3 }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 1 }}>
                <Typography variant="h6" sx={{ fontWeight: 600 }}>
                    Document Uploads
                </Typography>
                <EditNoteIcon
                    onClick={() => onEdit(2, refNo ? `/user/application-form?edit=document-upload&refNo=${refNo}` : "/user/application-form?edit=document-upload")}
                    sx={{ cursor: "pointer", color: "primary.main" }}
                />
            </Box>
            {/* {state.document_uploads.map((doc, index) => (
                <Box key={index} sx={{ mt: 1, pl: 2 }}>
                    <Typography variant="body2">
                        <strong>Type:</strong> {doc.doc_type}
                    </Typography>
                    <Typography variant="body2">
                        <strong>File:</strong> {doc.file_url || "Not uploaded"}
                    </Typography>
                </Box>
            ))} */}
            <Box sx={{ mt: 1, pl: 2 }}>
                <Typography variant="body2">
                    <strong>Type:</strong> {state.document_uploads?.doc_type}
                </Typography>
                <Typography variant="body2">
                    <strong>File:</strong> {state.document_uploads.file_url?.name || "Not uploaded"}
                </Typography>
            </Box>
            <Typography variant="h6" sx={{ mt: 3, fontWeight: 600 }}>
                Bank Details
            </Typography>
            <Box sx={{ pl: 2 }}>
                <Typography variant="body2"><strong>Bank Name:</strong> {state.bankdetails.bank_name || "N/A"}</Typography>
                <Typography variant="body2"><strong>Branch Name:</strong> {state.bankdetails.branch_name || "N/A"}</Typography>
                <Typography variant="body2"><strong>Account Holder:</strong> {state.bankdetails.account_holder || "N/A"}</Typography>
                <Typography variant="body2"><strong>Account Number:</strong> {state.bankdetails.ac_number || "N/A"}</Typography>
                <Typography variant="body2"><strong>Relation:</strong> {state.bankdetails.relation || "N/A"}</Typography>
                {state.bankdetails.relation === "Other" && (
                    <Typography variant="body2"><strong>Specify Relation:</strong> {state.bankdetails.other_relation || "N/A"}</Typography>
                )}
            </Box>
        </Box>
    );

    const renderPersonalInfo = () => (
        <Box sx={{ mb: 3 }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 1 }}>
                <Typography variant="h6" sx={{ fontWeight: 600 }}>
                    Personal Information
                </Typography>
                <EditNoteIcon
                    onClick={() => onEdit(0, refNo ? `/user/application-form?edit=personal-info&${refNo}` : "/user/application-form?edit=personal-info")}
                    sx={{ cursor: "pointer", color: "primary.main" }}
                />
            </Box>
            <Box sx={{ pl: 2 }}>
                <Typography variant="body2"><strong>Name:</strong> {state.personalInfo.firstName} {state.personalInfo.middleName} {state.personalInfo.lastName}</Typography>
                <Typography variant="body2"><strong>Email:</strong> {state.personalInfo.email}</Typography>
                <Typography variant="body2"><strong>Phone:</strong> {state.personalInfo.contactNumber}</Typography>
                <Typography variant="body2"><strong>Gender:</strong> {state.personalInfo.gender}</Typography>
                <Typography variant="body2"><strong>Referral Source:</strong> {state.personalInfo.heardAboutUs}</Typography>
            </Box>
        </Box>
    );

    const renderInstitutionDetails = () => (
        <Box sx={{ mb: 3 }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 1 }}>
                <Typography variant="h6" sx={{ fontWeight: 600 }}>
                    Recipients Details
                </Typography>
                <EditNoteIcon
                    onClick={() => onEdit(3, refNo ? `/user/application-form?edit=recipients-details&refNo=${refNo}` : "/user/application-form?edit=recipients-details")}
                    sx={{ cursor: "pointer", color: "primary.main" }}
                />
            </Box>
            <Box sx={{ pl: 2 }}>
                <Typography variant="body2">
                    <strong>Address:</strong> {state.recipientDetails?.addressLine1}, {state.recipientDetails?.city}, {state.recipientDetails?.state}, {state.recipientDetails?.country} - {state.recipientDetails?.pincode}
                </Typography>
                <Typography variant="body2">
                    <strong>Delivery Option:</strong> {state.recipientDetails.deliveryOption === "standard" ? "Standard ($12)" : "International ($81)"}
                </Typography>
                {state.applicationPurpose.includes("Education") && (
                    <Typography variant="body2">
                        <strong>Universities:</strong> {state.recipientDetails.universitiesSelected?.length > 0
                            ? state.recipientDetails.universitiesSelected.join(", ") // Display universities as a comma-separated list
                            : "N/A"}
                    </Typography>
                )}
                {state.applicationPurpose.includes("Immigration") && (
                    <Typography variant="body2">
                        <strong>Immigration Offices:</strong> {state.recipientDetails.institutesSelected?.length > 0
                            ? state.recipientDetails.institutesSelected.join(", ") // Display institutes as a comma-separated list
                            : "N/A"}
                    </Typography>
                )}
                {/* <pre>{JSON.stringify(state.recipientDetails, null, 2)}</pre> */}
            </Box>
        </Box>
    );

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setIsChecked((prev) => ({
            ...prev,
            [name]: checked,
        }));
    };

    const handleSubmit = () => {
        if (
            isChecked.verifyDocuments &&
            isChecked.certifyInformation &&
            isChecked.acceptTerms &&
            isChecked.acknowledgePrivacyPolicy
        ) {
            dispatch({ type: "UPDATE_STEP", payload: 5 });
        } else {
            alert("Please agree to all the terms and conditions before proceeding.");
        }
    };

    const validateFields = () => {
        const newErrors = {};
        const { verifyDocuments, certifyInformation, acceptTerms, acknowledgePrivacyPolicy } = isChecked;
        if (!verifyDocuments || !certifyInformation || !acceptTerms || !acknowledgePrivacyPolicy) {
            newErrors.checkboxes = "Please check all the given checkboxes"
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    }

    const handleNextStep = async (value) => {
        console.log("step5 payload:", state)
        if (!validateFields()) {
            return; // Prevent going to the next step
        }
        if (value === "dashboard") {
            navigate("/user/dashboard");
        } else {
            dispatch({ type: "UPDATE_STEP", payload: state.step + 1 });
        }
    };
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    console.log("params", searchParams.get("edit"), searchParams.get("refNo"))
    return (
        <Box sx={{ width: "100%", p: 4 }}>
            {renderPersonalInfo()}
            <Divider sx={{ my: 2 }} />
            {renderPurpose()}
            <Divider sx={{ my: 2 }} />
            {renderDocuments()}
            <Divider sx={{ my: 2 }} />
            {renderInstitutionDetails()}
            <Divider sx={{ my: 2 }} />

            <Box sx={{ mt: 3 }}>
                <Typography variant="h6" sx={{ fontWeight: 600, mb: 1 }}>Authorization</Typography>
                <Box>
                    <FormControlLabel
                        control={<Checkbox checked={isChecked.verifyDocuments} onChange={handleCheckboxChange} name="verifyDocuments" />}
                        label="I authorize UBV to verify the authenticity of my documents with the issuing institution."
                    />
                    <FormControlLabel
                        control={<Checkbox checked={isChecked.certifyInformation} onChange={handleCheckboxChange} name="certifyInformation" />}
                        label="I certify that the information I am submitting is accurate and authentic."
                    />
                </Box>
            </Box>

            <Box sx={{ mt: 3 }}>
                <Typography variant="h6" sx={{ fontWeight: 600, mb: 1 }}>Terms & Conditions and Privacy Policy</Typography>
                <Box>
                    <FormControlLabel
                        control={<Checkbox checked={isChecked.acceptTerms} onChange={handleCheckboxChange} name="acceptTerms" />}
                        label={
                            <>
                                I have read and accept the{" "}
                                <Link href="https://applicant.universalbankvalidation.com/terms-and-conditions" target="_blank">
                                    Terms and Conditions
                                </Link>
                            </>
                        }
                    />
                    <FormControlLabel
                        control={<Checkbox checked={isChecked.acknowledgePrivacyPolicy} onChange={handleCheckboxChange} name="acknowledgePrivacyPolicy" />}
                        label={
                            <>
                                I acknowledge receipt of the{" "}
                                <Link href="https://applicant.universalbankvalidation.com/privacy-policy" target="_blank">
                                    Privacy Policy
                                </Link>
                            </>
                        }
                    />
                    <Typography color="error" sx={{ fontWeight: 400, mb: 1, }}>{errors.checkboxes}</Typography>
                </Box>
            </Box>

            <Box sx={{ mt: 2, mx: 0, display: "flex", gap: 2 }}>
                <Button
                    disabled={state.step === 0}
                    variant="outlined"
                    onClick={() => dispatch({ type: "UPDATE_STEP", payload: state.step - 1 })}
                >
                    Back
                </Button>
                <Button
                    variant="contained"
                    // onClick={() =>
                    // (dispatch({ type: "UPDATE_STEP", payload: Math.min(state.step + 1, steps.length - 1) }),
                    //     state.step === steps.length - 1 && navigate('/dashboard'))
                    // }
                    onClick={handleNextStep}
                >
                    {"Next"}
                </Button>
                <Button
                    variant="contained"
                    color="success"
                    onClick={() => { handleNextStep("dashboard"); }}
                >
                    {"Save & Exit"}
                </Button>
            </Box>

            {/* <Button
                variant="contained"
                onClick={handleSubmit}
                sx={{ mt: 4, p: 1.5, fontSize: "16px", fontWeight: 600 }}
            >
                Proceed to Payment
            </Button> */}
        </Box>
    );
};

export default Step5Review;
