import { lazy } from "react";
import { Navigate } from "react-router-dom";

import AuthGuard from "./auth/AuthGuard";
import { authRoles } from "./auth/authRoles";

import Loadable from "./components/Loadable";
import MatxLayout from "./components/MatxLayout/MatxLayout";

import materialRoutes from "app/views/material-kit/MaterialRoutes";
import CustomerList from "./views/customers/CustomerList";
import CustomerViewPage from "./views/customers/ViewCustomer";
import NewCustomerPage from "./views/customers/NewCustomer";
import ProductList from "./views/products/ProductList";
import ViewProduct from "./views/products/ViewProduct";
import NewProductPage from "./views/products/NewProduct";
import OrderList from "./views/orders/OrderList";
import HelpCenterPage from "./views/helpcenter/HelpCenter";
import ViewOrderPage from "./views/orders/ViewOrder";
import OrderFormPage from "./views/forms/OrderForm";
import InvoiceFormPage from "./views/forms/InvoiceForm";
import AccountPage from "./views/accounts/AccountPage";
import ShopPage from "./views/ecommerce/ShopPage";
import CartPage from "./views/ecommerce/CartPage";
import CheckoutPage from "./views/ecommerce/CheckoutPage";
import CreatAccount from './views/accounts/CreateAccount';
import UserDetails from "./views/accounts/UserDetails";
import { FormContextProvider } from "./contexts/UserContext/FormContextProvider";
import Dashboard from "./views/accounts/MainPage";
import EvaluatorDashboard from "./parent-components/EvaluatorDashboard";
import { SecondarySidebar } from "./components/SecondarySidebar";
import ResetPassword from "./views/sessions/ResestPassword";
import MainPage from "./views/Institutes/MainPage";
import ReportPage from "./views/Institutes/ReportPage";
import ApplicationList from "./views/applications/ApplicationList";
import Chat from "./views/chat/chat";
import DataTableList from "./views/applications/DataTableList";
import AdminProfile from "./views/profile/AdminProfile";
import QCList from "./views/QC/List";
import CombineScreen from "./views/QC/CombineScreen";

// SESSION PAGES
const NotFound = Loadable(lazy(() => import("app/views/sessions/NotFound")));
const JwtLogin = Loadable(lazy(() => import("app/views/sessions/JwtLogin")));
const JwtRegister = Loadable(
  lazy(() => import("app/views/sessions/JwtRegister"))
);
const ForgotPassword = Loadable(
  lazy(() => import("app/views/sessions/ForgotPassword"))
);
// E-CHART PAGE
const AppEchart = Loadable(
  lazy(() => import("app/views/charts/echarts/AppEchart"))
);
// DASHBOARD PAGE
const Analytics = Loadable(lazy(() => import("app/views/dashboard/Analytics")));
console.log("authRoles::", authRoles)
const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      ...materialRoutes,
      // create account
      {
        path: "/user/create-account",
        element: <CreatAccount />,
        auth: authRoles.user,
      },
      {
        path: "/user/application-form",
        element: < FormContextProvider > <UserDetails /></FormContextProvider >,
        auth: authRoles.user,
      },
      { path: "/user/dashboard", element: < FormContextProvider ><Dashboard /></FormContextProvider >, auth: authRoles.user },
      { path: "/institute/dashboard", element: <MainPage />, auth: authRoles.institutes }
      // {
      //   path: "/evaluator-dashboard",
      //   element: <AuthGuard><EvaluatorDashboard /></AuthGuard>,
      //   auth: authRoles.evaluator, // Restrict access to evaluators
      // },
      // { path: "/institute/dashboard", element: <OrderList />, auth: authRoles.institutes },
    ],
  },

  // session pages route
  { path: "/session/404", element: <NotFound /> },
  { path: "/session/signin", element: <JwtLogin />, },
  { path: "/session/signup", element: <JwtRegister /> },
  { path: "/session/forgot-password", element: <ForgotPassword /> },
  { path: "/auth/reset-password", element: <ResetPassword /> },
  {
    path: "/institute/login", element: <JwtLogin />
  },
  { path: "/institute/auth/forgot-password", element: <ForgotPassword /> },
  { path: "/institute/auth/reset-password", element: <ResetPassword /> },
  { path: "/institute/report", element: <ReportPage /> },
  // { path: "/institute/dashboard", element: <MainPage /> },
  // { path: "/evaluator-dashboard", element: <OrderList /> },
  // { path: "/create-account", element: <CreatAccount /> },
  // { path: "/application-form", element: < FormContextProvider > <UserDetails /></FormContextProvider > },
  // { path: "/", element: <Navigate to="dashboard/default" /> },

  // checkers
  { path: "/documentlist", element: <ApplicationList /> },
  { path: "/chat", element: <Chat /> },
  { path: "/datatable", element: <DataTableList /> },
  { path: "/adminprofile", element: <AdminProfile /> },


  //OC routes
  { path: "/qc/list", element: <QCList /> },
  { path: "/qc/evaluation/:id", element: <CombineScreen /> },

  // { path: "/", element: <Navigate to="dashboard/default" /> },
  // { path: "*", element: <NotFound /> }

  { path: '/', element: <Navigate to="/session/signin" /> },
  { path: "*", element: <NotFound /> },
];

export default routes;
