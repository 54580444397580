import React, { useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
    Box,
    Typography,
    MenuItem,
    TextField,
    Button,
    Select,
    InputLabel,
    FormControl,
    Grid
} from "@mui/material";
import { FormContext } from "../../contexts/UserContext/FormContextProvider";
import { useEffect } from "react";
import axios from "axios";
import { UserBaseURL } from "app/contexts/JWTAuthContext";

const documentTypeOtion = ["Bank Statement", "Balance Confirmation", "Both"];
const relationOptions = [
    "Self",
    "Sibling",
    "Father",
    "Mother",
    "Brother",
    "Grand Father",
    "Grand Son",
    "Parent",
    "Child",
    "Grand Parent",
    "Grand Child",
    "Spouse",
    "Other",
];
export const countryOptions = [
    "United States",
    "Canada",
    "United Kingdom",
    "Australia",
    "Germany",
    "France",
    "Japan",
    "China",
    "India",
    "Brazil",
    "South Korea",
    "Italy",
    "Russia",
    "South Africa",
    "Netherlands",
    "Switzerland",
    "Sweden",
    "Norway",
    "Denmark",
    "Singapore",
    "Hong Kong",
    "United Arab Emirates",
    "Saudi Arabia",
    "Mexico",
    "Argentina",
    "Turkey",
    "Indonesia",
    "Malaysia",
    "Thailand",
    "Vietnam",
    "Philippines",
    "New Zealand",
    "Spain",
    "Portugal",
    "Ireland",
    "Poland",
    "Czech Republic",
    "Austria",
    "Belgium",
    "Finland",
    "Greece",
    "Hungary",
    "Israel",
    "Egypt",
    "Chile",
    "Colombia",
    "Peru",
    "Nigeria",
    "Kenya",
    "Pakistan",
    "Bangladesh",
    "Sri Lanka",
    "Qatar",
    "Kuwait",
    "Oman",
    "Bahrain",
    "Luxembourg",
    "Iceland",
];
const Step3DocumentUpload = () => {
    const { state, dispatch } = useContext(FormContext);
    const token = sessionStorage.getItem("token");
    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);
    const userId = sessionStorage.getItem("userId");
    const [documentType, setDocumentType] = useState("");
    const [file, setFile] = useState(null);
    const [bankDetails, setBankDetails] = useState(state.bankdetails);
    const [bankOptions, setBankOptions] = useState(["Bank A", "Bank B", "Bank C"]);
    const [branchOption, setBranchOptions] = useState(["Branch X", "Branch Y", "Branch Z"]);
    // const [countryOptions, setCountryOptions] = useState([]);
    const [errors, setErrors] = useState({});

    const documentDisclaimer = {
        "Bank Statement": "Please upload the file in PDF format for the last 3 months. No scanned or stamped statements are allowed.",
        "Balance Confirmation": "Please upload the confirmation for the current month.",
        "Both": "Please upload both Bank Statements and Balance Confirmation in PDF format.",
    };

    const handleDocumentTypeChange = (value) => {
        setDocumentType(value);
        dispatch({
            type: "UPDATE_DOCUMENT_TYPE",
            payload: { doc_type: value },
        });
        setErrors((prev) => ({ ...prev, documentType: "" }));
    };

    const handleFileUpload = (event) => {
        const uploadedFile = event.target.files[0];
        setFile(uploadedFile);
        dispatch({
            type: "UPDATE_DOCUMENT_UPLOAD",
            payload: { file_url: uploadedFile }
            // payload: { doc_type: documentType, file_url: uploadedFile.name },
        });
        setErrors((prev) => ({ ...prev, file: "" }));
    };

    const handleBankDetailsChange = (field, value) => {
        const updatedDetails = { ...bankDetails, [field]: value };
        setBankDetails(updatedDetails);
        dispatch({
            type: "UPDATE_BANK_DETAILS",
            payload: updatedDetails,
        });
        setErrors((prev) => ({ ...prev, [field]: "" }));
    };

    const validateFields = () => {
        const newErrors = {};

        if (!documentType) newErrors.documentType = "Document type is required.";
        if (!file) newErrors.file = "Please upload a file.";
        if (!bankDetails.country) newErrors.country = "Country is required.";
        if (!bankDetails.bank_name) newErrors.bank_name = "Bank name is required.";
        if (!bankDetails.branch_name) newErrors.branch_name = "Branch name is required.";
        if (!bankDetails.ifsc_code) newErrors.ifsc_code = "IFSC code is required.";
        if (!bankDetails.account_holder) newErrors.account_holder = "Account holder name is required.";
        if (!bankDetails.ac_number) newErrors.ac_number = "Account number is required.";
        if (!bankDetails.relation) newErrors.relation = "Relationship with account holder is required.";
        if (bankDetails.relation === "Other" && !bankDetails.other_relation)
            newErrors.other_relation = "Please specify the relation.";

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    }

    const handleNextStep = async (value) => {
        // console.log("step3 payload:", state.document_uploads)
        let payload = { ...state.bankdetails, ...state.document_uploads, applicationId: state.applicantDetail.id }
        let formData = new FormData()
        formData.append("applicationId", searchParams.get("refNo") || state.applicantDetail.id)
        formData.append("documentType", state.document_uploads.doc_type === "Bank Statement" ? "BANK_STATEMENT" : state.document_uploads.doc_type === "Balance Confirmation" ? "BALANCE_CONFIRMATION" : "BANK_STATEMENT_AND_BALANCE_CONFIRMATION",)
        formData.append("file", state.document_uploads.file_url)
        formData.append("country", payload.country)
        formData.append("bankName", payload.bank_name)
        formData.append("branchName", payload.branch_name)
        formData.append("ifscCode", payload.ifsc_code)
        formData.append("accountHolderName", payload.account_holder)
        formData.append("bankAccountNumber", payload.ac_number)
        formData.append("relationshipWithAccountHolder", payload.relation?.toUpperCase()?.split(" ")?.join(""))
        // formData.append("otherRelation", payload.other_relation)

        console.log("step 3 payload:", {
            applicationId: payload.applicationId,
            documentType: state.document_uploads.doc_type === "Bank Statement" ? "BANK_STATEMENT" : state.document_uploads.doc_type === "Balance Confirmation" ? "BALANCE_CONFIRMATION" : "BANK_STATEMENT_AND_BALANCE_CONFIRMATION",
            file: state.document_uploads.file_url,
            country: payload.country,
            bankName: payload.bank_name,
            branchName: payload.branch_name,
            ifscCode: payload.ifsc_code,
            accountHolderName: payload.account_holder,
            bankAccountNumber: payload.ac_number,
            relationshipWithAccountHolder: payload.relation,
            otherRelation: payload.other_relation,
        })

        // if no error then move to next step
        if (!validateFields()) {
            return;
        }
        // api call here patch
        try {
            await axios.post(`${UserBaseURL}/application/financial-details`, formData, { headers: { Authorization: `Bearer ${token}` } }).then((res) => {
                console.log("step3 response:", res.data);
                if (value === "dashboard") {
                    navigate("/user/dashboard");
                } else {
                    if (searchParams.get("edit")) {
                        dispatch({ type: "UPDATE_APPLICANT", payload: res.data });
                        dispatch({ type: "UPDATE_STEP", payload: 4 });
                        navigate("/user/application-form", { replace: true });
                    } else {
                        // api call here post
                        dispatch({ type: "UPDATE_APPLICANT", payload: res.data });
                        dispatch({ type: "UPDATE_STEP", payload: state.step + 1 });
                    }
                }

            }).catch((err) => {
                console.error("Error saving data:", err.response);
                // alert("Failed to save data. Please try again.");
            });
        } catch (error) {
            console.error("Error:", error);
        }
    };

    useEffect(() => {
        console.log("state", state.document_uploads)
        if (searchParams.get("edit")) {
            // setBankDetails(state.bankdetails);
            setDocumentType(state?.document_uploads?.doc_type || "");
            setFile(state?.document_uploads?.file_url || "");
            dispatch({
                type: "UPDATE_BANK_DETAILS",
                payload: state.bankdetails,
            });
            dispatch({
                type: "UPDATE_DOCUMENT_TYPE",
                payload: { doc_type: state?.document_uploads?.doc_type || "" },
            });
            dispatch({
                type: "UPDATE_DOCUMENT_UPLOAD",
                payload: { file_url: state?.document_uploads?.file_url || "" }
                // payload: { doc_type: documentType, file_url: uploadedFile.name },
            });
        }
        window.scrollTo(0, 0);
    }, [])

    return (
        <Box sx={{ width: "100%", p: 2 }}>
            {/* Document Type Dropdown */}
            {/* <FormControl fullWidth margin="normal">
                <InputLabel id="document-type-label">Document Type</InputLabel>
                <Select
                    labelId="document-type-label"
                    value={documentType}
                    onChange={(e) => handleDocumentTypeChange(e.target.value)}
                >
                    <MenuItem value="Bank Statement">Bank Statement</MenuItem>
                    <MenuItem value="Balance Confirmation">Balance Confirmation</MenuItem>
                    <MenuItem value="Both">Both</MenuItem>
                </Select>
            </FormControl> */}

            <TextField
                select
                label="Document Type"
                value={documentType}
                onChange={(e) => handleDocumentTypeChange(e.target.value)}
                fullWidth
                margin="normal"
                error={!!errors.documentType}
                helperText={errors.documentType}
            >
                {documentTypeOtion.map((doc) => (
                    <MenuItem key={doc} value={doc}>
                        {doc}
                    </MenuItem>
                ))}
            </TextField>

            {/* Disclaimer */}
            {documentType && (
                <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
                    {documentDisclaimer[documentType]}
                </Typography>
            )}

            {/* File Upload */}
            {documentType && (
                <Box sx={{ mt: 3 }}>
                    <Button variant="contained" component="label">
                        Upload File
                        <input
                            type="file"
                            hidden
                            onChange={handleFileUpload}
                        />
                    </Button>
                    {file && <Typography variant="body2" sx={{ mt: 1 }}>{file.name}</Typography>}
                    {errors.file && (
                        <Typography variant="body2" color="error">
                            {errors.file}
                        </Typography>
                    )}
                </Box>
            )}

            {/* Bank Information */}
            {file && (
                <Box sx={{ mt: 3 }}>
                    {/* Bank Name */}
                    {/* <FormControl fullWidth margin="normal">
                        <InputLabel id="bank-name-label">Bank Name</InputLabel>
                        <Select
                            labelId="bank-name-label"
                            value={bankDetails.bank_name}
                            onChange={(e) => handleBankDetailsChange("bank_name", e.target.value)}
                        >
                            <MenuItem value="Bank A">Bank A</MenuItem>
                            <MenuItem value="Bank B">Bank B</MenuItem>
                            <MenuItem value="Bank C">Bank C</MenuItem>
                        </Select>
                    </FormControl> */}
                    <Grid container>
                        <Grid item xs={12} sm={6}>
                            {/* Country to be add in state */}
                            <TextField
                                select
                                label="Country"
                                value={bankDetails.country}
                                onChange={(e) => handleBankDetailsChange("country", e.target.value)}
                                fullWidth
                                margin="normal"
                                error={!!errors.country}
                                helperText={errors.country}
                            >
                                {countryOptions.map((country) => (
                                    <MenuItem key={country} value={country}>
                                        {country}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    </Grid>
                    {bankDetails.country &&
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    select
                                    label="Bank Name"
                                    value={bankDetails.bank_name}
                                    onChange={(e) => handleBankDetailsChange("bank_name", e.target.value)}
                                    fullWidth
                                    margin="normal"
                                    error={!!errors.bank_name}
                                    helperText={errors.bank_name}
                                >
                                    {bankOptions.map((bank) => (
                                        <MenuItem key={bank} value={bank}>
                                            {bank}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    // select
                                    label="Branch Name"
                                    value={bankDetails.branch_name}
                                    onChange={(e) => handleBankDetailsChange("branch_name", e.target.value)}
                                    fullWidth
                                    margin="normal"
                                    error={!!errors.branch_name}
                                    helperText={errors.branch_name}
                                />
                                {/* {branchOption.map((branch) => (
                                        <MenuItem key={branch} value={branch}>
                                            {branch}
                                        </MenuItem>
                                    ))}
                                </TextField> */}
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    fullWidth
                                    label="IFSC Code"
                                    value={bankDetails.ifsc_code}
                                    onChange={(e) => handleBankDetailsChange("ifsc_code", e.target.value)}
                                    margin="normal"
                                    error={!!errors.ifsc_code}
                                    helperText={errors.ifsc_code}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    fullWidth
                                    label="Account Holder Name"
                                    value={bankDetails.account_holder}
                                    onChange={(e) => handleBankDetailsChange("account_holder", e.target.value)}
                                    margin="normal"
                                    error={!!errors.account_holder}
                                    helperText={errors.account_holder}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    fullWidth
                                    label="Account Number"
                                    value={bankDetails.ac_number}
                                    onChange={(e) => handleBankDetailsChange("ac_number", e.target.value)}
                                    margin="normal"
                                    error={!!errors.ac_number}
                                    helperText={errors.ac_number}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    select
                                    label="Relation"
                                    value={bankDetails.relation}
                                    onChange={(e) => handleBankDetailsChange("relation", e.target.value)}
                                    fullWidth
                                    margin="normal"
                                    error={!!errors.relation}
                                    helperText={errors.relation}
                                >
                                    {relationOptions.map((relation) => (
                                        <MenuItem key={relation} value={relation}>
                                            {relation}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            {bankDetails.relation === "Other" && (
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        fullWidth
                                        label="Specify Relation"
                                        value={bankDetails.other_relation}
                                        onChange={(e) => handleBankDetailsChange("other_relation", e.target.value)}
                                        margin="normal"
                                        error={!!errors.other_relation}
                                        helperText={errors.other_relation}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    }

                </Box >
            )}
            <Box sx={{ mt: 2, mx: 0, display: "flex", gap: 2 }}>
                <Button
                    disabled={state.step === 0}
                    variant="outlined"
                    onClick={() => dispatch({ type: "UPDATE_STEP", payload: state.step - 1 })}
                >
                    Back
                </Button>
                <Button
                    variant="contained"
                    // onClick={() =>
                    // (dispatch({ type: "UPDATE_STEP", payload: Math.min(state.step + 1, steps.length - 1) }),
                    //     state.step === steps.length - 1 && navigate('/dashboard'))
                    // }
                    onClick={handleNextStep}
                >
                    {"Next"}
                </Button>
                <Button
                    variant="contained"
                    color="success"
                    onClick={() => { handleNextStep("dashboard"); }}
                >
                    {"Save & Exit"}
                </Button>
            </Box>
        </Box >
    );
};

export default Step3DocumentUpload;
