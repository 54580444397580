import React from 'react';
import { Box, Typography, Paper, Link } from '@mui/material';
import ResponsiveAppBar from 'app/components/Appbar';

const AdminProfile = () => {
    return (
        <>
            <ResponsiveAppBar />
            <Box
                sx={{
                    // display: 'flex',
                    // justifyContent: 'center',
                    // alignItems: 'center',
                    // padding: 2,
                    backgroundColor: '#f4f6f8',
                    // height: '100vh',
                    marginTop: "10px"
                }}
            >
                <Paper
                    elevation={3}
                    sx={{
                        padding: 3,
                        // maxWidth: 400,
                        borderRadius: 2,
                    }}
                >
                    <Link href="/datatable">Home</Link>
                    <Typography variant="h4" gutterBottom>
                        Profile
                    </Typography>
                    <Box sx={{ marginLeft: "10px" }}>
                        <Box sx={{ marginBottom: 2 }}>
                            <Typography variant="body1" sx={{ fontWeight: "500" }} >
                                Username:Bhumi Mistry
                            </Typography>
                        </Box>
                        <Box sx={{ marginBottom: 2 }}>
                            <Typography variant="body1" sx={{ fontWeight: "500" }}>
                                Email:bhumi@mistry.com
                            </Typography>
                        </Box>
                        <Box sx={{ marginBottom: 2 }}>
                            <Typography variant="body1" sx={{ fontWeight: "500" }}>
                                Mobile Number:7010701070
                            </Typography>
                        </Box>
                        <Box sx={{ marginBottom: 2 }}>
                            <Typography variant="body1" sx={{ fontWeight: "500" }}>
                                Club Type:  USER
                            </Typography>
                        </Box>
                        <Box sx={{ marginBottom: 2 }}>
                            <Typography variant="body1" sx={{ fontWeight: "500" }}>
                                Wallet Balance: 28,538.00
                            </Typography>
                        </Box>
                    </Box>
                </Paper>
            </Box>
        </>
    );
};

export default AdminProfile;
