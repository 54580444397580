export const QCFetch = {
    status: "success",
    evaluation_requests: [
        {
            application_id: "a477d472-e403-4a09-8b88-4b1c6d416543",
            user_name: "John Cena",
            submitted_at: "2024-11-19T10:00:00Z",
            status: "Pending",
            document_type: "BankStatement"
        },
        {
            application_id: "456e7890-e21b-45c3-a987-426614174001",
            user_name: "Jane Smith",
            submitted_at: "2024-11-18T15:30:00Z",
            status: "In_Progress",
            document_type: "BalanceConfirmation"
        },
        {
            application_id: "456e7890-e21b-45c3-a987-426614174002",
            user_name: "Harry Khan",
            submitted_at: "2024-10-20T10:00:00Z",
            status: "Completed",
            document_type: "BalanceConfirmation"
        },
        {
            application_id: "456e7890-e21b-45c3-a987-426614174003",
            user_name: "Triple H",
            submitted_at: "2024-09-10T15:00:00Z",
            status: "Pending",
            document_type: "BalanceConfirmation"
        },
        {
            application_id: "456e7890-e21b-45c3-a987-426614174004",
            user_name: "Alex Joe",
            submitted_at: "2024-01-01T10:00:00Z",
            status: "In_Progress",
            document_type: "BalanceConfirmation"
        }
    ]
};

export const QCDocument = {
    status: "success",
    application: {
        documents: [
            {
                document_id: "111e4567-e89b-12d3-a456-426614174000",
                document_type: "BankStatement",
                file_url: "https://example.com/document1.pdf"
            }
        ],
        checklist_questions: [
            {
                question_id: "222e4567-e89b-12d3-a456-426614174000",
                question: "Is the document legible?",
                order: 1
            },
            {
                question_id: "333e4567-e89b-12d3-a456-426614174000",
                question: "Does the document have the correct stamp?",
                order: 2
            }
        ],
        submitted_at: "2024-11-19T10:00:00Z"
    }
};

export const EvaluatorFetch = {
    status: "success",
    evaluation_requests: [
        // {
        //     application_id: "ABC123",
        //     submitted_at: "2024-11-19T10:00:00Z",
        //     status: "Pending",
        //     document_type: "BankStatement",
        //     priority: "Low"
        // },
        // {
        //     application_id: "DEF234",
        //     //   user_name: "Jane Smith",
        //     submitted_at: "2024-11-18T15:30:00Z",
        //     status: "In_Progress",
        //     document_type: "BalanceConfirmation",
        //     priority: "Medium"
        // },
        // {
        //     application_id: "GHI654",
        //     submitted_at: "2024-10-01T10:00:00Z",
        //     status: "Completed",
        //     document_type: "BankStatement",
        //     priority: "High"
        // },
        {
            application_id: "JKL278",
            submitted_at: "2024-09-05T10:00:00Z",
            status: "Pending",
            document_type: "BankStatement",
            priority: "Low"
        },
        {
            application_id: "MNP987",
            submitted_at: "2024-08-02T10:00:00Z",
            status: "In_Progress",
            document_type: "BankStatement",
            priority: "Low"
        }
    ]
};

export const EvaluatorChat = {
    status: "success",
    applications: [
        {
            application_id: "MNP987",
            submitted_at: "2024-11-19T10:00:00Z",
            document_type: "BankStatement",
            file_url: "https://example.com/document1.pdf",
            authority_email: "authority@example.com",
            status: "In_Progress",
            chat_history: [
                {
                    message_id: "111e4567-e89b-12d3-a456-426614174000",
                    sender: "Evaluator",
                    // message: "Please verify the document.",
                    message: '<p>Please <strong>Verify </strong>the document</p><p><br></p>',
                    timestamp: "2024-11-19T11:00:00Z"
                },
                {
                    message_id: "222e4567-e89b-12d3-a456-426614174001",
                    sender: "Authority",
                    // message: "The document is valid.",
                    // message: '<p><strong>HELLO</strong></p><p><strong><span></span></strong></p>',
                    message: '<p>This <em>document </em>is <strong>not </strong>valid</p><p><br></p>',
                    timestamp: "2024-11-19T12:00:00Z"
                }
            ]
        },
        {
            application_id: "JKL278",
            submitted_at: "2024-11-19T10:00:00Z",
            document_type: "BankStatement",
            file_url: "https://example.com/document1.pdf",
            authority_email: "authority@example.com",
            status: "In_Progress",
            chat_history: [
                {
                    message_id: "111e4567-e89b-12d3-a456-426614174000",
                    sender: "Evaluator",
                    // message: "Please verify the document.",
                    message: '<p>Hello <strong>How</strong>Are you</p><p><br></p>',
                    timestamp: "2024-11-19T11:00:00Z"
                },
                {
                    message_id: "222e4567-e89b-12d3-a456-426614174001",
                    sender: "Authority",
                    // message: "The document is valid.",
                    // message: '<p><strong>HELLO</strong></p><p><strong><span></span></strong></p>',
                    message: '<p>I <em>Am </em>is <strong>not </strong>find</p><p><br></p>',
                    timestamp: "2024-11-19T12:00:00Z"
                }
            ]
        }
    ]
};

