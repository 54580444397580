import { Container, Box, useScrollTrigger } from '@mui/material';
import ResponsiveAppBar from 'app/components/Appbar';
import DataTable from 'app/components/DataTable';
import { formatDate } from 'app/utils/utils';
import { QCFetch } from 'dummy_data/data';
import React from 'react'
import { useState, useEffect } from 'react';
import { fetchDashboardData } from 'services/apiService';
import { formatDocumentType } from 'app/utils/FormatText';

function List() {
    const [qcData, setQcData] = useState(null)
    const [error, setError] = useState(null);

    useEffect(() => {
        const getData = async () => {
            try {
                const dashboardData = await fetchDashboardData();
                setQcData(dashboardData?.evaluations);
            } catch (err) {
                setError(err.message || 'Failed to fetch data');
            }
        };

        getData();
    }, []);

    console.log("qc", qcData)

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!qcData) {
        return <div>Loading...</div>;
    }

    const columns = [
        {
            name: "evaluationId",
            label: "Evaluation",
            options: {
                // display: false,
            },
        },
        {
            name: "status",
            label: "Status",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => (
                    <span
                        style={{
                            display: "inline-block",
                            width: "100px",
                            border: "1px solid #75b5d9", // Uniform border color
                            padding: "4px 8px",
                            borderRadius: "4px",
                            color: "#75b5d9", // Text color matching the border
                            textAlign: "center",
                            marginLeft: "10px",
                        }}
                    >
                        {formatDocumentType(value)}
                    </span>
                ),
            },
        },
        {
            name: "documentType",
            label: "Document Type",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (v) => <>{formatDocumentType(v)}</>,
            },
        },
        {
            name: "submittedAt", // Moved to the bottom to appear later
            label: "Submit Date",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => formatDate(value),
            },
        },
    ];


    // { "success": true, "evaluations": [{ "evaluationId": "a477d472-e403-4a09-8b88-4b1c6d416543", "submittedAt": "2024-12-07T15:46:32.133Z", "status": "QC_PENDING", "documentType": "BANK_STATEMENT" }, { "evaluationId": "a477d472-e403-4a09-8b88-4b1c6d416547", "submittedAt": "2024-12-07T15:46:32.133Z", "status": "QC_PENDING", "documentType": "BANK_STATEMENT" }] }
    return (
        <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
            <ResponsiveAppBar title="QC" />
            {/* <Box sx={{ backgroundColor: "#75b5d9" }}> */}
            <Box
                sx={{
                    flex: 1,
                    backgroundColor: "#75b5d9",
                    pt: 3, // Padding-top for separation
                    pb: 3, // Padding-bottom for consistency
                }}
            >
                <Container>
                    <DataTable
                        //  list={QCFetch.evaluation_requests}
                        list={qcData}
                        column={columns} title="List" QCList />
                </Container>
            </Box>
        </Box>
    )
}

export default List