import React from "react";
import {
    Container,
    Box,
    Typography,
    Button,
    Card,
    CardContent,
    styled,
    TablePagination
} from "@mui/material";
import { Fragment } from "react";
import Swal from "sweetalert2";
import HeaderUBV from "app/components/HeaderUBV";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { FormContext } from "app/contexts/UserContext/FormContextProvider";
import axios from "axios";
import { UserBaseURL } from "app/contexts/JWTAuthContext";
import { useState } from "react";
import { useEffect } from "react";

// import noData from "../../../../public/assets/ubv-images/images/file.png";

const applications = [
    {
        refNo: "12345",
        date: "2023-11-20",
        id: 1,
        step: 2, // Step where the user left off
        personalInfo: {
            fullName: "John Doe",
            email: "john.doe@example.com",
            phone: "+1234567890",
            otp: "1234",
            gender: "Male",
            referralSource: "Social Media",
        },
        applicationPurpose: ["Education"],
        customPurpose: "Study Abroad",
        bankdetails: {
            country: "USA",
            bank_name: "Bank of America",
            branch_name: "Downtown",
            ifsc_code: "BOA12345",
            account_holder: "John Doe",
            ac_number: "123456789",
            relation: "Self",
            other_relation: "",
        },
        document_uploads: [
            { doc_type: "Passport", file_url: "/uploads/passport.pdf" },
            { doc_type: "Visa", file_url: "/uploads/visa.pdf" },
        ],
        recipientDetails: {
            recipientType: "Self",
            country: "India",
            addressLine1: "123 Street",
            addressLine2: "Apt 5",
            state: "Maharashtra",
            city: "Mumbai",
            pincode: "400001",
            deliveryOption: "standard",
            isCourierDelivery: true,
            institutesSelected: ["Immigration Office X"],
            universitiesSelected: ["University A"],
        },
        paymentInfo: {
            promoCode: "DISCOUNT10",
            paymentBreakdown: {
                reportCharge: 95,
                courierCharge: 20,
                discount: 10,
                total: 105,
            },
            paymentStatus: "Pending",
        },
        status: "Pending", // Application status
    },
    {
        refNo: "67890",
        date: "2023-11-18",
        id: 2,
        step: 4,
        personalInfo: {
            fullName: "Jane Smith",
            email: "jane.smith@example.com",
            phone: "+1987654321",
            otp: "5678",
            gender: "Female",
            referralSource: "Friend",
        },
        applicationPurpose: ["Immigration"],
        customPurpose: "",
        bankdetails: {
            country: "UK",
            bank_name: "HSBC",
            branch_name: "City Center",
            ifsc_code: "HSBC67890",
            account_holder: "Jane Smith",
            ac_number: "987654321",
            relation: "Self",
            other_relation: "",
        },
        document_uploads: [
            { doc_type: "ID Proof", file_url: "/uploads/idproof.pdf" },
        ],
        recipientDetails: {
            recipientType: "Guardian",
            country: "Canada",
            addressLine1: "789 Maple St",
            addressLine2: "",
            state: "Ontario",
            city: "Toronto",
            pincode: "M5H2N2",
            deliveryOption: "international",
            isCourierDelivery: true,
            institutesSelected: ["Immigration Office Y"],
            universitiesSelected: [],
        },
        paymentInfo: {
            promoCode: "WELCOME5",
            paymentBreakdown: {
                reportCharge: 95,
                courierCharge: 81,
                discount: 5,
                total: 171,
            },
            paymentStatus: "Completed",
        },
        status: "Approved",
    },
];

// const StyledRoot = styled("div")(() => ({
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center",
//     backgroundColor: "#76B3D8",
//     minHeight: "100%",
//     padding: "2rem",
//     marginTop: "5rem",
//     "& .card-container": {
//         width: "100%",
//         maxWidth: "1200px",
//         backgroundColor: "rgba(0, 0, 0, 0.1)",
//         padding: "20px"
//     },
//     "& .application-card": {
//         width: "100%",
//         marginBottom: "1rem",
//         padding: "1.5rem",
//         backgroundColor: "#fff",
//         display: "flex",
//         flexDirection: "column",
//         boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
//         borderRadius: "8px",
//     },
//     "& .card-header": {
//         display: "flex",
//         justifyContent: "space-between",
//         alignItems: "center",
//         marginBottom: "1rem",
//     },
//     "& .status": {
//         padding: "4px 8px",
//         borderRadius: "4px",
//         color: "#fff",
//     },
// }));

const StyledRoot = styled("div")(() => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#76B3D8",
    // minHeight: "100%",
    padding: "1rem",
    // marginTop: "5rem",
    "& .card-container": {
        width: "100%",
        maxWidth: "1200px",
        backgroundColor: "rgba(288, 288, 288, 0.9)",
        padding: "1rem",
        boxSizing: "border-box",
    },
    "& .nodata-card": {
        width: "100%",
        height: "400px",
        marginBottom: "1rem",
        padding: "1rem",
        // backgroundColor: "#fff",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // flexDirection: "column",
        // boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        border: "1px solid rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
    },
    "& .application-card": {
        width: "100%",
        marginBottom: "1rem",
        padding: "1rem",
        backgroundColor: "#fff",
        display: "flex",
        flexDirection: "column",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
    },
    "& .card-header": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: "wrap", // Wrap on smaller screens
        gap: "1rem",
        marginBottom: "2rem"
    },
    "& .status": {
        padding: "4px 8px",
        borderRadius: "4px",
        color: "#fff",
    },
    "@media (max-width: 768px)": {
        padding: "1rem",
        marginTop: "2rem",
        "& .card-header": {
            // flexDirection: "column", // Stack on smaller screens
            // alignItems: "flex-start",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        },
        "& .application-card": {
            padding: "0.8rem",
        },
        "& .card-container": {
            padding: "0.5rem",
        },
    },
    "@media (max-width: 480px)": {
        "& .card-header": {
            alignItems: "stretch",
        },
        "& .application-card": {
            padding: "0.5rem",
        },
    },
}));

const Dashboard = () => {
    const navigate = useNavigate();
    const { dispatch } = useContext(FormContext);
    const [applications, setApplications] = useState([]);
    const [totalCount, setTotalCount] = useState(15);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const token = sessionStorage.getItem("token");
    // const handleViewDetails = (refNo) => {
    //     alert(`View details for reference: ${refNo}`);
    // };
    const dummyArr = [
        {
            "id": "48PGVB",
            "userId": "74fc0c43-d82d-4f96-bde7-bbc2fc1041b7",
            "applicationStatus": "SUBMITTED",
            "purpose": "EDUCATION",
            "firstName": "John",
            "middleName": "Robert",
            "lastName": "Doe",
            "gender": "male",
            "dateOfBirth": "1990-01-01",
            "email": "john.doe@example.com",
            "contactNumber": "+1234567890",
            "applicationStage": "RECEIPT_DETAILS",
            "deliveryOption": "STANDARD",
            "paymentId": "737d4d4e-d7d0-4ba4-b4d7-a48756d0b64f",
            "bankName": "Chase Bank",
            "bankAccountNumber": "1234567890",
            "accountHolderName": "John Doe",
            "ifscCode": "",
            "relationshipWithAccountHolder": "SELF",
            "documentType": "BANK_STATEMENT",
            "fileKey": "documents/1733433722678-Test.pdf",
            "addressId": "715dd019-fc53-48aa-af1a-391b5e9fe8bf",
            "createdAt": "2024-12-05T18:15:36.726Z",
            "submittedAt": "2024-12-07T21:16:31.442Z"
        },
        {
            "id": "0IUWCI",
            "userId": "23cca2ef-c19f-4289-ae1a-3080829669d5",
            "applicationStatus": "DRAFT",
            "purpose": null,
            "firstName": "Kadir",
            "middleName": "Farid",
            "lastName": "",
            "gender": "Male",
            "dateOfBirth": "2001-10-10",
            "email": "kadir.s+13@allabroadeducation.com",
            "contactNumber": "7854678546",
            "applicationStage": "PERSONAL_DETAILS",
            "deliveryOption": "EMAIL",
            "paymentId": null,
            "bankName": null,
            "bankAccountNumber": null,
            "accountHolderName": null,
            "ifscCode": null,
            "relationshipWithAccountHolder": null,
            "documentType": null,
            "fileKey": "documents/1733433722678-Test.pdf",
            "addressId": null,
            "createdAt": "2024-12-07T20:56:10.048Z",
            "submittedAt": null
        },
    ];
    const checkStep = (value) => {
        switch (value) {
            case "PERSONAL_DETAILS":
                return 1;
            case "PURPOSE":
                return 2;
            case "FINANCIAL_DETAILS":
                return 3;
            case "PAYMENT":
                return 0;
            case "RECIPIENT_DETAILS":
                return 5;
            case "COMPLETED":
                return 0;
            default:
                return 0;

        }
    }
    const toPage = (step) => {
        switch (step) {
            case 0:
                return "personal-info"
            case 1:
                return "purpose"
            case 2:
                return "document-upload"
            case 3:
                return "recipients-details"
            case 6:
                return "payments"
            default:
                return "personal-info"
        }
    }
    const transformData = (array) => {
        return array.map((item) => ({
            refNo: item.id,
            date: item.createdAt ? item.createdAt.split("T")[0] : null, // Extract date from createdAt
            id: item.userId,
            step: checkStep(item.applicationStage), // Stage where the user left off
            personalInfo: {
                fullName: [item.firstName, item.middleName, item.lastName].filter(Boolean).join(" "),
                email: item.email,
                phone: item.contactNumber,
                otp: "", // Assuming OTP is not available in the input
                gender: item.gender,
                referralSource: "", // Assuming referralSource is not provided
            },
            applicationPurpose: item.purpose ? [item.purpose] : [],
            customPurpose: item.purpose || "Not Specified",
            bankdetails: {
                country: "", // Assuming country is not provided
                bank_name: item.bankName || "Not Specified",
                branch_name: "", // Assuming branch_name is not provided
                ifsc_code: item.ifscCode || "Not Specified",
                account_holder: item.accountHolderName || "Not Specified",
                ac_number: item.bankAccountNumber || "Not Specified",
                relation: item.relationshipWithAccountHolder || "Not Specified",
                other_relation: "", // Assuming other_relation is not provided
            },
            document_uploads: item.documentType
                ? [{ doc_type: item.documentType, file_url: `/uploads/${item.fileKey}` }]
                : [],
            recipientDetails: {
                recipientType: "Self", // Default value
                country: "", // Assuming country is not provided
                addressLine1: "", // Assuming address is not provided
                addressLine2: "", // Assuming address is not provided
                state: "", // Assuming state is not provided
                city: "", // Assuming city is not provided
                pincode: "", // Assuming pincode is not provided
                deliveryOption: item.deliveryOption ? item.deliveryOption.toLowerCase() : "standard",
                isCourierDelivery: item.deliveryOption?.toLowerCase() === "courier",
                institutesSelected: [], // Assuming institutesSelected is not provided
                universitiesSelected: [], // Assuming universitiesSelected is not provided
            },
            paymentInfo: {
                promoCode: "", // Assuming promoCode is not provided
                paymentBreakdown: {
                    reportCharge: 0, // Default value
                    courierCharge: 0, // Default value
                    discount: 0, // Default value
                    total: 0, // Default value
                },
                paymentStatus: item.paymentId ? "Completed" : "Pending",
            },
            applicationsStage: item.applicationStage,
            // status: item.applicationStatus === "SUBMITTED" ? "Submitted" : "Pending",
            status: item.applicationStatus
        }));
    };

    const handleViewDetails = (refNo) => {
        // Find the selected application
        const selectedApplication = applications.find((app) => app.refNo === refNo);
        const editPage = toPage(selectedApplication.step);
        if (selectedApplication && selectedApplication.step !== 0) {
            // Update global state with the selected application's details
            dispatch({
                type: "SET_USER_DETAILS",
                payload: selectedApplication, // Pass the entire application
            });

            // Navigate to UserDetails to resume
            // dispatch({ type: "UPDATE_STEP", payload: selectedApplication.step });
            navigate(`/user/application-form?refNo=${refNo}`);
        } else if (selectedApplication.applicationsStage === "PAYMENT") {
            Swal.fire({ icon: "info", title: "Application is submitted", timer: 3000 })
        } else {
            // alert("Application not found!");
            Swal.fire({ icon: "info", title: "Application not found!", timer: 3000 })
        }
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const getAllApplications = async () => {
        await axios.get(`${UserBaseURL}/application`, { headers: { Authorization: `Bearer ${token}` }, params: { page: page, limit: rowsPerPage } }).then((res) => {
            // console.log("applications:", res.data);
            setApplications(transformData(res.data.data));
            setTotalCount(res.data.total);
        }).catch((err) => {
            // setApplications(transformData(dummyArr))
            console.log(err);
        })
    }

    const handleCreateNew = () => {
        // navigate("/user/create-account");
        navigate("/user/application-form");
    };

    console.log("application::", applications);

    useEffect(() => {
        // getAllApplications();
        // setApplications(transformData(dummyArr));
        dispatch(({ type: "RESET_FORM" }))
    }, [])
    useEffect(() => {
        getAllApplications();
    }, [page, rowsPerPage])

    return (
        <Fragment>
            {/* <HeaderUBV /> */}
            <StyledRoot>
                <Box className="card-container">
                    <Box className="card-header">
                        <Typography variant="h4">My Applications</Typography>
                        <Button variant="contained" color="primary" onClick={handleCreateNew}>
                            Create New Application
                        </Button>
                    </Box>
                    {applications.length === 0 ?
                        <Box className="nodata-card" >
                            <img src={"/assets/ubv-images/images/file.png"} alt="no-data" />
                        </Box>
                        : applications.map((app) => (
                            <Card key={app.refNo} className="application-card">
                                <CardContent>
                                    <Box display="flex" justifyContent="space-between">
                                        <Typography variant="h6">Reference No: {app.refNo}</Typography>
                                        <span
                                            className="status"
                                            style={{
                                                backgroundColor:
                                                    app.status === "SUBMITTED" || app.status === "APPROVED"
                                                        ? "#28a745"
                                                        : app.status === "UNDER_REVIEW"
                                                            ? "#1976D2"
                                                            : app.status === "REJECTED"
                                                                ? "#dc3545"
                                                                : "#ffc107",
                                            }}
                                        >
                                            {app.status === "UNDER_REVIEW" ? "UNDER REVIEW" : app.status}
                                        </span>
                                    </Box>
                                    <Typography variant="body1">Date: {app.date}</Typography>
                                    <Box mt={2}>
                                        <Button
                                            variant="outlined"
                                            onClick={() => handleViewDetails(app.refNo)}
                                        >
                                            {app.status === "SUBMITTED" ? "Completed" : "Continue"}
                                        </Button>
                                    </Box>
                                </CardContent>
                            </Card>
                        ))}
                    {/* Pagination */}
                    {applications.length !== 0 && (<TablePagination
                        component="div"
                        count={totalCount}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        rowsPerPageOptions={[5, 10, 25]}
                        labelRowsPerPage="Rows per page:"
                    />)}
                </Box>
            </StyledRoot>
        </Fragment>
    );
};

export default Dashboard;
