import React, { useContext } from "react";
import { Fragment } from "react";
import { Stepper, Step, StepLabel, Button, Box, styled, Card, useMediaQuery, useTheme, MobileStepper, Typography } from "@mui/material";
import { FormContext } from "../../contexts/UserContext/FormContextProvider";
import Step1PersonalInfo from "../../components/UserForm/Step1PersonalInfo";
import Step2AdditionalInfo from "../../components/UserForm/Step2AdditionalInfo";
import Step3DocumentUpload from "../../components/UserForm/Step3DocumentUpload";
import Step5Review from "../../components/UserForm/Step5Review";
import Step6Payment from "../../components/UserForm/Step6Payment";
import Step4InstitutionDetails from "app/components/UserForm/Step4InstitutionDetails";
import { useNavigate } from "react-router-dom";
import HeaderUBV from "app/components/HeaderUBV";

import axios from "axios";
import { useEffect } from "react";


// STYLED COMPONENTS
const FlexBox = styled(Box)(() => ({
    display: "flex",
}));

const ContentBox = styled("div")(() => ({
    height: "100%",
    padding: "32px",
    paddingTop: "0px",
    position: "relative",
    // background: "rgba(0, 0, 0, 0.01)",
}));
export const AlertContentBox = styled("div")(() => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "2rem",
    maxWidth: "600px",
    padding: "32px",
}));

const StyledRoot = styled("div")(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#76B3D8",
    // backgroundColor: "rgba(0, 0, 0, 0.01)",
    // minHeight: "100% !important",
    // margin: 0,
    // position: "relative",
    // top: 0,
    // left: 150,
    // marginTop: "-50px",
    "& .card": {
        maxWidth: "100%",
        minHeight: 400,
        margin: "1rem",
        // marginTop: "-150px",
        display: "flex",
        borderRadius: 12,
        alignItems: "center",
    },
    // ".card > *": {
    //     margin: "0 !important",
    //     padding: "0 !important"
    // },
    ".img-wrapper": {
        height: "100%",
        minWidth: 320,
        display: "flex",
        padding: "2rem",
        alignItems: "center",
        justifyContent: "center",
    },
    "@media (max-width: 480px)": {
        "& .card": {
            maxWidth: 600,
            marginTop: "120px"
        },
    },
    "@media (min-width: 768px)": {
        "& .card": {
            maxWidth: 600,
            marginTop: "120px"
        },
    },
    "@media (min-width: 1024px)": {
        "& .card": {
            maxWidth: 1400,
            width: 1200,
            margin: "1rem",
        },
    },
}));

const steps = ["Personal Info", "Purpose", "Document Upload", "Recipients Details", "Review", "Payment"];

const ResponsiveStepper = ({ activeStep, handleNext, handleBack }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    return isMobile ? (
        <Box sx={{ maxWidth: 400, flexGrow: 1 }}>
            <Typography variant="h6">{steps[activeStep]}</Typography>
            <MobileStepper
                variant="text"
                steps={steps.length}
                position="static"
                activeStep={activeStep}
            // nextButton={
            //     <Button size="small" onClick={handleNext} disabled={activeStep === steps.length - 1}>
            //         Next
            //     </Button>
            // }
            // backButton={
            //     <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            //         Back
            //     </Button>
            // }
            />
        </Box>
    ) : (
        <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
                <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                </Step>
            ))}
        </Stepper>
    );
};

const UserDetails = () => {
    const { state, dispatch } = useContext(FormContext);
    const navigate = useNavigate();
    const renderStepContent = (step) => {
        switch (step) {
            case 0: return <Step1PersonalInfo />;
            case 1: return <Step2AdditionalInfo />;
            case 2: return <Step3DocumentUpload />;
            case 3: return <Step4InstitutionDetails />;
            case 4: return <Step5Review />;
            case 5: return <Step6Payment />;
            default: return <div>Unknown Step</div>;
        }
    };

    const handleEditStep = (step, route) => {
        dispatch({ type: "UPDATE_STEP", payload: step });
        navigate(route, { state: { step: step } })
    };
    useEffect(() => {
        window.scrollTo(0, 0);
        // dispatch({ type: "UPDATE_STEP", payload: 3 });
        // if (!state.step) {
        //     dispatch({ type: "UPDATE_STEP", payload: state.step }); // Ensure it starts at the first step
        // }
        // state.step, dispatch
    }, [])
    // console.log("state::", state)
    return (
        <Fragment>
            {/* <HeaderUBV /> */}
            <StyledRoot>
                <Card className="card">
                    <Box sx={{ width: "100%", padding: "32px" }}>
                        {/* <Stepper activeStep={state.step} alternativeLabel>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper> */}
                        <ResponsiveStepper activeStep={state.step} />
                        <Box>{state.step === 4 ? <Step5Review onEdit={handleEditStep} /> : renderStepContent(state.step)}</Box>
                        {/* <Box sx={{ mt: 2, mx: 2, display: "flex", gap: 2 }}>
                            <Button
                                disabled={state.step === 0}
                                variant="outlined"
                                onClick={() => dispatch({ type: "UPDATE_STEP", payload: state.step - 1 })}
                            >
                                Back
                            </Button>
                            <Button
                                variant="contained"
                                // onClick={() =>
                                // (dispatch({ type: "UPDATE_STEP", payload: Math.min(state.step + 1, steps.length - 1) }),
                                //     state.step === steps.length - 1 && navigate('/dashboard'))
                                // }
                                onClick={handleNext}
                            >
                                {state.step === steps.length - 1 ? "Proceed to Payment" : "Next"}
                            </Button>
                        </Box> */}
                    </Box>
                </Card>
            </StyledRoot>
        </Fragment>
    )
}

export default UserDetails