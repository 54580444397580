import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
    Button,
    Card,
    Grid,
    styled,
    TextField,
    Typography,
    Box,
    IconButton,
    InputAdornment,
} from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { Fragment } from "react";
import HeaderUBV from "app/components/HeaderUBV";
import { CalendarToday } from "@mui/icons-material";
import axios from "axios";
import { UserBaseURL } from "app/contexts/JWTAuthContext";

// STYLED COMPONENTS
const StyledRoot = styled("div")(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#76B3D8",
    // backgroundColor: "rgba(0, 0, 0, 0.01)",
    minHeight: "100vh !important",

    "& .card": {
        maxWidth: "100%",
        margin: "1rem",
        marginTop: "180px",
        borderRadius: 12,
    },

    ".img-wrapper": {
        display: "flex",
        padding: "2rem",
        alignItems: "center",
        justifyContent: "center",
    },
    "@media (min-width: 768px)": {
        "& .card": {
            width: 600,
        },
    },
    "@media (min-width: 1024px)": {
        "& .card": {
            width: 500,
            margin: "1rem",
        },
    },
}));

const ContentBox = styled("div")(({ theme }) => ({
    padding: 32,
    background: "#fff",
    paddingTop: 0
    // background: theme.palette.background.default,
}));

export default function ResetPassword() {
    const navigate = useNavigate();
    const location = useLocation();
    const isInstitute = location.pathname.startsWith("/institute");
    const searchParams = new URLSearchParams(location.search);
    const initialValues = {
        password: "",
        confirmPassword: "",
    };
    const [response, setResponse] = useState({
        status: "error",
        message: "",
        open: false,
    })
    console.log(searchParams.get("code"));
    // form field validation schema
    const validationSchema = Yup.object().shape({
        password: Yup.string()
            .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})/, "Password must contain at least one lowercase letter, one uppercase letter, one special character, and be at least 8 characters long.")
            .min(8, "Password must be 6 characters or more")
            .required("Password is required!"),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], "Passwords must match")
            .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})/, "Password must contain at least one lowercase letter, one uppercase letter, one special character, and be at least 8 characters long.")
            .min(8, "Password must be 6 characters or more")
            .required("Confirm Password is required!"),
    });
    const handleFormSubmit = async (values) => {
        // console.log(email);
        let url = isInstitute ? `${UserBaseURL}/auth/reset-password` : `${UserBaseURL}/auth/reset-password`;
        let role = isInstitute ? "INSTITUTE_USER" : "USER"; // ADMIN
        await axios.post(url, { code: searchParams.get("code"), newPassword: values.password })
            .then((res) => { console.log("forgot", res.data); Swal.fire({ icon: "success", title: "Your password has been updated.", timer: 3000 }).then((res) => { navigate("/session/signin"); }) })
            .catch((err) => { console.log(err.response); Swal.fire({ icon: "error", title: "Failed to create account. Please try again.", timer: 3000 }) });
    };

    return (
        <Fragment>
            <HeaderUBV />
            <StyledRoot>
                <Card className="card">
                    <Grid container>
                        <Grid item xs={12}>
                            <Box p={4} pb={0} className="auth-container">
                                <Box className="auth-heading">
                                    <h3>{"Reset Password"}</h3>
                                </Box>
                            </Box>

                            <ContentBox>
                                <Formik
                                    onSubmit={handleFormSubmit}
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}
                                >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                    }) => (
                                        <form onSubmit={handleSubmit}>
                                            <TextField
                                                fullWidth
                                                size="medium"
                                                name="password"
                                                type="password"
                                                label="Password"
                                                variant="outlined"
                                                onBlur={handleBlur}
                                                value={values.password}
                                                onChange={handleChange}
                                                helperText={touched.password && errors.password}
                                                error={Boolean(errors.password && touched.password)}
                                                sx={{ mb: 3 }}
                                            />
                                            <TextField
                                                fullWidth
                                                size="medium"
                                                name="confirmPassword"
                                                type="password"
                                                label="Confirm Password"
                                                variant="outlined"
                                                onBlur={handleBlur}
                                                value={values.confirmPassword}
                                                onChange={handleChange}
                                                helperText={touched.confirmPassword && errors.confirmPassword}
                                                error={Boolean(errors.confirmPassword && touched.confirmPassword)}
                                                sx={{ mb: 3 }}
                                            />
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                color="primary"
                                                type="submit"
                                                sx={{ marginTop: 3 }}
                                            >
                                                Submit
                                            </Button>

                                            <Button
                                                fullWidth
                                                color="primary"
                                                variant="outlined"
                                                onClick={() => navigate(-1)}
                                                sx={{ mt: 2 }}
                                            >
                                                Go Back
                                            </Button>
                                        </form>
                                    )}
                                </Formik>
                            </ContentBox>
                        </Grid>
                    </Grid>
                </Card>
            </StyledRoot>
        </Fragment>
    );
}
