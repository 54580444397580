import React, { useState } from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { IconButton, TextField, Box, Button } from "@mui/material";
import { FilterList } from "@mui/icons-material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import DateRangeIcon from "@mui/icons-material/DateRange";

export default function BasicPopover({ onDateChange }) {
    const [anchorEl, setAnchorEl] = useState(null);
    // const [selectedDate, setSelectedDate] = useState(null);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // const handleSubmit = () => {
    //     onDateChange(selectedDate); // Pass the selected date to the parent
    //     handleClose(); // Close the popover
    // };
    const handleSubmit = () => {
        onDateChange({ fromDate, toDate });
        handleClose();
    };

    // const handleReset = () => {
    //     setSelectedDate(null); // Reset the selected date
    //     onDateChange(null); // Notify the parent of the reset
    //     handleClose(); // Close the popover
    // };

    const handleReset = () => {
        setFromDate(null);
        setToDate(null);
        onDateChange({ fromDate: null, toDate: null });
        handleClose();
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    return (
        <div>
            <Button
                variant="outlined"
                onClick={handleClick}
                startIcon={<DateRangeIcon />}
                style={{ height: "40px" }} // Match search/filter button height
            >
                Date Filter
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            >
                {" "}
                <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2, minWidth: "250px" }}>
                    {" "}
                    <Typography>Date Range</Typography>{" "}
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        {" "}
                        <DatePicker
                            label="From"
                            value={fromDate}
                            onChange={(newValue) => setFromDate(newValue)}
                            renderInput={(props) => (
                                <TextField {...props} variant="outlined" size="small" sx={{ width: "100%" }} />
                            )}
                        />{" "}
                        <DatePicker
                            label="To"
                            value={toDate}
                            minDate={fromDate}
                            onChange={(newValue) => setToDate(newValue)}
                            renderInput={(props) => (
                                <TextField {...props} variant="outlined" size="small" sx={{ width: "100%" }} />
                            )}
                        />{" "}
                    </LocalizationProvider>{" "}
                    <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1, mt: 2 }}>
                        {" "}
                        <Button variant="outlined" size="small" onClick={handleReset}>
                            {" "}
                            Reset{" "}
                        </Button>{" "}
                        <Button variant="contained" size="small" onClick={handleSubmit}>
                            {" "}
                            Submit{" "}
                        </Button>{" "}
                    </Box>{" "}
                </Box>{" "}
            </Popover>{" "}
        </div>
    );
}
