import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
    Card,
    Checkbox,
    Grid,
    TextField,
    FormControlLabel,
    Button,
    Box,
    styled,
} from "@mui/material";
import { Fragment } from "react";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Swal from "sweetalert2";
import HeaderUBV from "app/components/HeaderUBV";
import { UserBaseURL } from "../../contexts/JWTAuthContext"

// STYLED COMPONENTS
// const FlexBox = styled(Box)(() => ({
//     display: "flex",
// }));

// const ContentBox = styled("div")(() => ({
//     height: "100%",
//     padding: "32px",
//     paddingTop: "0px",
//     position: "relative",
// }));

// const StyledRoot = styled("div")(() => ({
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//     backgroundColor: "#76B3D8",
//     minHeight: "100% !important",
//     marginTop: "2rem",
//     "& .card": {
//         maxWidth: "100%",
//         minHeight: 400,
//         margin: "1rem",
//         marginTop: "180px",
//         display: "flex",
//         borderRadius: 12,
//         alignItems: "center",
//     },
// }));

const FlexBox = styled(Box)(() => ({
    display: "flex",
}));

const ContentBox = styled("div")(() => ({
    height: "100%",
    padding: "32px",
    paddingTop: "0px",
    position: "relative",
    // background: "rgba(0, 0, 0, 0.01)",
}));
export const AlertContentBox = styled("div")(() => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "2rem",
    maxWidth: "600px",
    padding: "32px",
}));

const StyledRoot = styled("div")(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#76B3D8",
    // backgroundColor: "rgba(0, 0, 0, 0.01)",
    minHeight: "100% !important",
    marginTop: "2rem", // need to reomve after auth rote is final
    "& .card": {
        maxWidth: "100%",
        minHeight: 400,
        margin: "1rem",
        marginTop: "180px",
        display: "flex",
        borderRadius: 12,
        alignItems: "center",
    },

    ".img-wrapper": {
        height: "100%",
        minWidth: 320,
        display: "flex",
        padding: "2rem",
        alignItems: "center",
        justifyContent: "center",
    },
    "@media (min-width: 768px)": {
        "& .card": {
            maxWidth: 600,
        },
    },
    "@media (min-width: 1024px)": {
        "& .card": {
            maxWidth: 1400,
            width: 1200,
            margin: "1rem",
        },
    },
}));


const CreateAccount = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const userId = sessionStorage.getItem("userId");
    const token = sessionStorage.getItem("token");
    const initialValues = {
        firstName: "",
        middleName: "",
        lastName: "",
        dateOfBirth: "",
        email: sessionStorage.getItem("email") || "",
        password: "",
        confirmPassword: "",
        hasReference: false,
        referralCode: "",
        noLastName: false,
        newsletterSubscription: true,
    };
    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required('Please enter your first name'),
        middleName: Yup.string().required('Please enter your middle name'),
        dateOfBirth: Yup.string().required('Please enter your D.O.B'),
        email: Yup.string()
            .email("Invalid Email address")
            .required("Email is required!"),
        password: Yup.string()
            .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})/, "Password must contain at least one lowercase letter, one uppercase letter, one special character, and be at least 8 characters long.")
            .min(8, "Password must be 6 characters or more")
            .required("Password is required!"),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], "Passwords must match")
            .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})/, "Password must contain at least one lowercase letter, one uppercase letter, one special character, and be at least 8 characters long.")
            .min(8, "Password must be 6 characters or more")
            .required("Confirm Password is required!"),
    });
    const handleSubmit = async (values, { setSubmitting }) => {

        let payload = { ...values, lastName: values.noLastName ? "Unknown" : values.lastName }
        delete payload.noLastName
        delete payload.confirmPassword
        delete payload.hasReference
        delete payload.email
        console.log("payload::", payload)
        try {
            const response = await axios.patch(
                `${UserBaseURL}/user/${userId}/profile`,
                JSON.stringify(payload),
                { headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` } }

            );
            if (response.status === 200) {
                console.log("create::", response.data)
                // alert("Account created successfully!");
                Swal.fire({ icon: "success", title: "Account created successfully!", timer: 3000 }).then((res) => {
                    setSubmitting(false);
                    navigate("/user/dashboard"); // Redirect to a success page
                })

            }
        } catch (error) {
            console.error("Error:", error);
            // alert("Failed to create account. Please try again.");
            Swal.fire({ icon: "error", title: "Failed to create account. Please try again.", timer: 3000 })
            setSubmitting(false);
        }
    };

    return (
        <Fragment>
            <HeaderUBV />
            <StyledRoot>
                <Card className="card">
                    <Grid container>
                        <Grid item sm={12} xs={12}>
                            <h2 style={{ padding: "20px 52px", marginTop: "0px", marginBottom: "0px" }}>Create Account</h2>
                            <ContentBox>
                                <Formik
                                    className="forms-container"
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}
                                    onSubmit={handleSubmit}
                                >
                                    {({
                                        values,
                                        handleChange,
                                        handleSubmit,
                                        setFieldValue,
                                        handleBlur,
                                        errors,
                                        touched,
                                        isSubmitting,
                                    }) => (
                                        <form onSubmit={handleSubmit}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={4}>
                                                    <TextField
                                                        label="First Name"
                                                        name="firstName"
                                                        value={values.firstName}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        fullWidth
                                                        required
                                                        error={Boolean(touched.firstName && errors.firstName)}
                                                        helperText={touched.firstName && errors.firstName ? errors.firstName : ""}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <TextField
                                                        label="Middle Name"
                                                        name="middleName"
                                                        value={values.middleName}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        fullWidth
                                                        required
                                                        error={Boolean(touched.middleName && errors.middleName)}
                                                        helperText={touched.middleName && errors.middleName ? errors.middleName : ""}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <TextField
                                                        label="Last Name"
                                                        name="lastName"
                                                        value={values.noLastName ? "Unknown" : values.lastName}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        fullWidth
                                                        disabled={values.noLastName}
                                                        error={Boolean(touched.lastName && errors.lastName)}
                                                        helperText={touched.lastName && errors.lastName}
                                                    />
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={values.noLastName}
                                                                onChange={() =>
                                                                    setFieldValue("noLastName", !values.noLastName)
                                                                }
                                                            />
                                                        }
                                                        label="Do not have a Last Name"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <TextField
                                                        label="Date of Birth"
                                                        name="dateOfBirth"
                                                        type="date"
                                                        value={values.dateOfBirth}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        fullWidth
                                                        required
                                                        InputLabelProps={{ shrink: true }}
                                                        error={Boolean(touched.dateOfBirth && errors.dateOfBirth)}
                                                        helperText={touched.dateOfBirth && errors.dateOfBirth ? errors.dateOfBirth : ""}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <TextField
                                                        label="Email"
                                                        name="email"
                                                        type="email"
                                                        value={values.email}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        fullWidth
                                                        required
                                                        disabled={true}
                                                        error={Boolean(touched.email && errors.email)}
                                                        helperText={touched.email && errors.email ? errors.email : ""}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={4}></Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <TextField
                                                        label="Password"
                                                        name="password"
                                                        type="password"
                                                        value={values.password}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        fullWidth
                                                        required
                                                        error={Boolean(touched.password && errors.password)}
                                                        helperText={touched.password && errors.password ? errors.password : ""}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <TextField
                                                        label="Confirm Password"
                                                        name="confirmPassword"
                                                        type="password"
                                                        value={values.confirmPassword}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        fullWidth
                                                        required
                                                        error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                                                        helperText={touched.confirmPassword && errors.confirmPassword ? errors.confirmPassword : ""}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={values.hasReference}
                                                                onChange={() =>
                                                                    setFieldValue("hasReference", !values.hasReference)
                                                                }
                                                            />
                                                        }
                                                        label="Do you have a Refferal Code?"
                                                    />
                                                </Grid>
                                                {values.hasReference && (
                                                    <Grid item xs={12} sm={4}>
                                                        <TextField
                                                            label="Reference Number"
                                                            name="referralCode"
                                                            value={values.referralCode}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            fullWidth
                                                            required
                                                        />
                                                    </Grid>
                                                )}
                                                <Grid item xs={12}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={values.newsletterSubscription}
                                                                onChange={() =>
                                                                    setFieldValue("newsletterSubscription", !values.newsletterSubscription)
                                                                }
                                                            />
                                                        }
                                                        label="Subscribe to UBV Newsletter."
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <Button
                                                        variant="outlined"
                                                        color="primary"
                                                        fullWidth
                                                        onClick={() => navigate(-1)}
                                                    >
                                                        Cancel
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <Button
                                                        type="submit"
                                                        variant="contained"
                                                        color="primary"
                                                        fullWidth
                                                        disabled={isSubmitting}
                                                    >
                                                        Create Account
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    )}
                                </Formik>
                            </ContentBox>
                        </Grid>
                    </Grid>
                </Card>
            </StyledRoot>
        </Fragment>
    );
};

export default CreateAccount;
