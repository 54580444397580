import React, { useState } from 'react';
import {
    Card, CardContent, Typography, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow, Checkbox, IconButton,
    TablePagination, TableSortLabel,
    Button, Box, TextField
} from '@mui/material';
import { FilterList, Done, Clear, TrendingFlat, Start, Search } from '@mui/icons-material';
import Popover from '@mui/material/Popover';
import ChatIcon from '@mui/icons-material/Chat';
import SendIcon from '@mui/icons-material/Send';
import { useNavigate } from 'react-router-dom';
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import BasicPopover from 'app/components/Popover';




const ApplicationList = () => {

    const [orders, setOrders] = useState([
        {
            id: 'd3a2f7f4-18c6-4d33-8c1e-7f4d3b6faa5f',
            customer: 'Ben Schieldman',
            product: 'Bit Bass Headphone',
            date: '29 Sep, 2024',
            status: 'delivered',
            method: 'PayPal',
            total: 15.25,
            cartImage: 'paypal.png',
        },
        {
            id: '6b2f4c3f-8f41-4464-8a7e-0de73d0f7f45',
            customer: 'Joyce Watson',
            product: 'Comlion Watch',
            date: '29 Sep, 2024',
            status: 'cancelled',
            method: 'Visa Card',
            total: 75.25,
            cartImage: 'visa.png',
        },
        {
            id: 'a6fc4f70-2c3f-4b7c-9c13-6f6b25d7c9cc',
            customer: 'Kayle Brown',
            product: 'Beats Headphone',
            date: '29 Sep, 2024',
            status: 'processing',
            method: 'Master Card',
            total: 45.25,
            cartImage: 'master-card.png',
        },
        {
            id: 'f7f9b6a0-8b16-46e6-9994-5f2c6b90a8a8',
            customer: 'Ven Helsing',
            product: 'BMW Bumper',
            date: '29 Sep, 2024',
            status: 'delivered',
            method: 'Master Card',
            total: 2145.25,
            cartImage: 'master-card.png',
        },
        {
            id: 'f7f9b6a0-8b16-46e6-9994-5f2c6b90a8a9',
            customer: 'Sandy Foster',
            product: 'Nike Shoes',
            date: '29 Sep, 2024',
            status: 'pending',
            method: 'Amazon Pay',
            total: 75.25,
            cartImage: 'amazon.png',
        },
        {
            id: 'f7f9b6a0-8b16-46e6-9994-5f2c6b90a8aa',
            customer: 'Mark Zuckerberg',
            product: 'Meta Watch',
            date: '29 Sep, 2024',
            status: 'delivered',
            method: 'Western Union',
            total: 2145.25,
            cartImage: 'western-union.png',
        },
        {
            id: 'f7f9b6a0-8b16-46e6-9994-5f2c6b90a8ab',
            customer: 'Steve Jobs',
            product: 'Apple Macbook',
            date: '29 Sep, 2024',
            status: 'cancelled',
            method: 'Amex',
            total: 105.25,
            cartImage: 'amex.png',
        }
    ]);

    const navigate = useNavigate()

    const [application, setApplication] = useState([
        {
            SrNo: 1,
            ReferenceId: 'REF-8374GZPL',
            document: 'BAnk statement',
            date: '29 Sep, 2024',
            status: 'Pending',
            priority: "Low"

        },
        {
            SrNo: 2,
            ReferenceId: 'REF-1942KQWY',
            document: 'BAnk statement',
            date: '29 Sep, 2024',
            status: 'Under Review',
            priority: "Medium"

        },
        {
            SrNo: 3,
            ReferenceId: 'REF-5267MTVX',
            document: 'BAnk statement',
            date: '29 Sep, 2024',
            status: 'Pending',
            priority: "High"

        },
        {
            SrNo: 4,
            ReferenceId: 'REF-3059NLRJ',
            document: 'BAnk statement',
            date: '29 Sep, 2024',
            status: 'Pending',
            priority: "Low"

        },
        {
            SrNo: 5,
            ReferenceId: 'REF-7481BCXF',
            document: 'BAnk statement',
            date: '29 Sep, 2024',
            status: 'Under Review',
            priority: "Medium"

        },
    ])

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [filterDate, setFilterDate] = useState(null); // Holds the selected date for filtering

    const handleDateChange = (date) => {
        setFilterDate(date);
        console.log('Selected Date:', date); // You can use this for filtering logic
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleStatusChange = (orderId, status) => {
        setOrders((prevOrders) =>
            prevOrders.map((order) =>
                order.id === orderId ? { ...order, status } : order
            )
        );
    };

    const handleRowClick = (refId) => {
        navigate(`/chat/${refId}`)
    }


    const filteredApplications = filterDate
        ? application.filter((app) => app.date === filterDate.toISOString().split("T")[0])
        : application;


    return (
        <Card>
            <CardContent>
                {/* Toolbar */}
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {/* <Typography variant="h6">All Orders</Typography> */}
                    <Typography variant="h5">Applications</Typography>
                    {/* <IconButton aria-label="Filter list">
                        <FilterList />
                    </IconButton> */}
                </div>
                <Box display="flex" alignItems="center" mb={2}>
                    <TextField
                        placeholder="Search Reference..."
                        sx={{
                            mr: 2,
                            mt: 2,
                            // Target the input's padding
                            '& .MuiInputBase-input': {
                                padding: "10px 60px 10px 10px"
                            },
                        }}
                    />
                    <BasicPopover onDateChange={handleDateChange} />


                    {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                value={filterDate}
                                onChange={(newDate) => setFilterDate(newDate)}
                                renderInput={(props) => (
                                    <TextField
                                        {...props}
                                        variant="outlined"
                                        size="small"
                                        sx={{ width: "200px" }}
                                    />
                                )}
                            />
                        </LocalizationProvider> */}



                    {/* <IconButton aria-describedby={id} variant="contained" onClick={handleClick}>
                        <FilterList />
                    </IconButton> */}
                    {/* <Button variant="outlined" color="primary" size='small'>Browse Catalog</Button> */}
                </Box>


                {/* Table */}
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {/* <TableCell padding="checkbox">
                                    <Checkbox />
                                </TableCell> */}
                                <TableCell>
                                    <TableSortLabel sx={{ color: "#8f9aa3" }}>Sr No</TableSortLabel>
                                </TableCell>
                                <TableCell >
                                    <TableSortLabel>ReferenceId</TableSortLabel>
                                </TableCell>
                                <TableCell align="center">
                                    <TableSortLabel>Document</TableSortLabel>
                                </TableCell>
                                <TableCell align="center">
                                    <TableSortLabel>Date</TableSortLabel>
                                </TableCell>
                                <TableCell align="center">
                                    <TableSortLabel>Status</TableSortLabel>
                                </TableCell>
                                <TableCell align="center">Priority</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {application
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((app) => (
                                    <TableRow key={app.SrNo} onClick={() => handleRowClick(app.ReferenceId)} style={{ cursor: "pointer" }}>
                                        <TableCell sx={{ color: "#8f9aa3" }}>{app.SrNo}</TableCell>
                                        <TableCell>{app.ReferenceId}</TableCell>
                                        <TableCell align="center">{app.document}</TableCell>
                                        <TableCell align="center">{app.date}</TableCell>
                                        <TableCell align="center">
                                            <span
                                                style={{
                                                    backgroundColor:
                                                        app.status === 'Pending'
                                                            ? 'rgba(9, 182, 109, 1)'
                                                            : app.status === 'Under Review'
                                                                ? '#FF3D57'
                                                                : '#FFAF38',
                                                    padding: '4px 8px',
                                                    borderRadius: '4px',
                                                    color: '#fff',
                                                }}
                                            >
                                                {app.status}
                                            </span>
                                        </TableCell>
                                        <TableCell align="center">
                                            <span
                                                style={{
                                                    backgroundColor:
                                                        app.priority === 'Low'
                                                            ? 'rgba(9, 182, 109, 1)' // Green
                                                            : app.priority === 'Medium'
                                                                ? '#FFAF38' // Orange
                                                                : app.priority === 'High'
                                                                    ? '#FF3D57' // Red
                                                                    : '#ccc', // Default color
                                                    padding: '4px 8px',
                                                    borderRadius: '4px',
                                                    color: '#fff',
                                                }}
                                            >
                                                {app.priority}
                                            </span>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                {/* Pagination */}
                <TablePagination
                    component="div"
                    count={application.length}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    rowsPerPageOptions={[5, 10, 25]}
                    labelRowsPerPage="Rows per page:"
                />
            </CardContent>
        </Card>
    );
};

export default ApplicationList;