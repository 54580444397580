import axios from 'axios';
import { baseUrl } from 'Domain';
// const api = axios.create({
//     baseURL: "/api", // Tüm isteklerde bu URL kullanılacak
//     timeout: 5000,   // İsteklerin zaman aşımı süresi
// });

// export const login = (email, password) => api.post('/auth/login', { email, password });
// export const register = (email, username, password) => api.post('/auth/register', { email, username, password });
// export const logout = () => api.post('/auth/logout');
// export const getProfile = () => api.get('/auth/profile');

// export const getNotifications = () => api.get('/notification');
// export const deleteNotification = (id) => api.post('/notification/delete', { id });
// export const clearNotifications = () => api.post('/notification/delete-all');
// export const createNotification = (notification) => api.post('/notification/add', { notification });

// export default api;

const BASE_URL = baseUrl;

// Create an axios instance
const apiClient = axios.create({
    baseURL: BASE_URL,
    timeout: 5000,
});

// Function to fetch data from qc/dashboard
export const fetchDashboardData = async () => {
    try {
        const response = await apiClient.get('/qc/dashboard');
        return response.data;
    } catch (error) {
        console.error('Error fetching dashboard data:', error);
        throw error;
    }
};

export const fetchEvalutorDashboardData = async () => {
    try {
        const response = await apiClient.get('/evaluator/dashboard')
        return response.data
    } catch (error) {
        console.error('Error fetching Evalutor data:', error);
        throw error;
    }
}

export const fetchQCDocumentQuestions = async (id) => {
    try {
        const response = await apiClient.get(`/qc/evaluation/${id}`)
        return response.data
    } catch (error) {
        console.error('Error fetching Evalutor data:', error);
        throw error;
    }
}


export const submitQCDocumentReviews = async (submission, id) => {
    try {
        const response = await apiClient.post(`/qc/evaluation/${id}/review`, submission);
        return response.data;
    } catch (error) {
        console.error('Error submitting QC document reviews:', error);
        throw error;
    }
};

export const fetchChat = async (id) => {
    try {
        const response = await apiClient.get(`/evaluator/evaluation/${id}/chat`);
        return response.data;
    } catch (error) {
        console.error('Error fetching chat:', error);
        throw error;
    }
}

export const sendFollowUpChat = async (id, message) => {
    try {
        const response = await apiClient.post(`/evaluator/evaluation/${id}/follow-up`, { message });
        return response.data;
    } catch (error) {
        console.error('Error Posting Follow Up Chat:', error);
        throw error;
    }
};

export const fetchEvalutorDetailsdData = async (id) => {
    try {
        const response = await apiClient.get(`/evaluator/evaluation/${id}`)
        return response.data
    } catch (error) {
        console.error('Error fetching Evalutor Details data:', error);
        throw error;
    }
}


export const submitEvalutorApproveReject = async (body, id) => {
    try {
        const response = await apiClient.post(`/evaluator/evaluation/${id}/complete`, body);
        return response.data;
    } catch (error) {
        console.error('Error submitting Evalutor Response:', error);
        throw error;
    }
};
